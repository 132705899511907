import { userType, userTypeToIntegration } from '../storage'
import { isLocalHost } from '../utils'

type Environment = 'development' | 'integration' | 'production' | 'unknown'
type FeatureName = 'signUp'

export interface FeatureFlag {
  description: string
  env: Environment
}

export type FeatureFlags = {
  [key in FeatureName]: FeatureFlag
}

export const isFeatureEnabled = (feature: FeatureFlag) => {
  if (feature.env === 'unknown') {
    return false
  }
  if (feature.env === 'production') {
    return true
  }
  if (feature.env === 'development' && isLocalHost) {
    return true
  }
  if (
    feature.env === 'integration' &&
    (localStorage.getItem(userType) === userTypeToIntegration || isLocalHost)
  ) {
    return true
  }
  return false
}
