import { SessionData } from '../../components/Context/Session.context'
import { GlobalPositionInfo } from '../../models/users.models'
import { gpDomainModelToModel } from '../mappers/globalPosition.mappers'
import { onGetHousesEconomicInfo } from './houses.services'
import { getSaving } from './savings.services'
import { getMySecurities } from './securities.service'

export const onGetGPInfo = async (
  sessionData: SessionData,
): Promise<GlobalPositionInfo> => {
  const [housesInfo, saving, securities] = await Promise.all([
    onGetHousesEconomicInfo({ userId: sessionData.id }),
    getSaving(sessionData.id),
    getMySecurities(sessionData.id, sessionData.currency),
  ])

  return gpDomainModelToModel(
    housesInfo,
    saving,
    securities,
    sessionData.currency,
  )
}
