import { Navigate, Outlet, useLocation } from 'react-router'
import * as React from 'react'
import { AlertsContext } from './Context/Alerts.context'
import { FeatureFlag, isFeatureEnabled } from '../utils/feature/features.utils'
import { userType, userTypeToIntegration } from '../utils/storage'

export const FeatureRoute = ({
  redirectPath = '/',
  children,
  showSubscriptionModal = false,

  feature,
}: {
  redirectPath?: string
  children?: any
  showSubscriptionModal?: boolean
  feature: FeatureFlag
}) => {
  const location = useLocation()
  const isBeta = location.pathname.includes('beta')

  const showBetaModal =
    isBeta && !(localStorage.getItem(userType) === userTypeToIntegration)
  const { setModalSubscriptionOpened, setBetaModal } =
    React.useContext(AlertsContext)

  if (isFeatureEnabled(feature)) {
    return children ? children : <Outlet />
  }
  if (isBeta) {
    if (showBetaModal) {
      setBetaModal(true)
    }
    return children ? children : <Outlet />
  }

  setModalSubscriptionOpened('subscriptionOptional')
  return <Navigate to={redirectPath} replace />
}
