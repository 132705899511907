import * as React from 'react'
import { useHelmet } from '../../../hooks/useHelmet'
import investmentIMG from '../../../assets/images/invest/investmentGraphic.jpg'

export const BegingToInvest = () => {
  return (
    <>
      {useHelmet({
        title: 'Cómo empezar a invertir',
        description:
          '📈 Aprende cómo empezar a invertir en finanzas: desde establecer objetivos hasta elegir las mejores opciones de inversión, tu guía completa para entrar en el mundo de las inversiones.',
        keywords: 'Empezar a invertir',
      })}
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h1 className="mb-4">
              <u>¿Cómo dar los primeros pasos en la inversión financiera?</u>
            </h1>
            <p>
              En esta guía te orientaremos para iniciar tu camino en la
              inversión financiera. Si lo deseas, puedes acceder a nuestra Guía
              del Ahorrador al Inversor.
            </p>
            <h2 className="mt-4">¿Cómo empezar a invertir en finanzas?</h2>
            <p>
              Primero, establece tus metas financieras. Definir tus metas te
              permitirá trazar una estrategia, considerando el nivel de riesgo
              que estás dispuesto a asumir.
            </p>
            <ul>
              <li>
                ¿Estás ahorrando para un objetivo específico, como la jubilación
                o aumentar tus ahorros?
              </li>
              <li>
                ¿Tienes un plazo determinado para tus ahorros, basado en tu edad
                u otros factores?
              </li>
              <li>
                ¿Dependes de estas inversiones para alcanzar tus objetivos, o
                simplemente estás explorando nuevas opciones de crecimiento?
              </li>
            </ul>
            <h3 className="mt-4">Filosofía de Inversión Pausada</h3>
            <p>
              Primero, establece tus metas financieras. En segundo lugar,
              identifica tu perfil de inversión:
            </p>
            <ul>
              <li>
                <strong>Conservador:</strong> buscas inversiones seguras, aunque
                impliquen menor rentabilidad.
              </li>
              <li>
                <strong>Moderado:</strong> deseas estabilidad con un cierto
                grado de rentabilidad, dispuesto a asumir riesgos a medio plazo.
              </li>
              <li>
                <strong>Dinámico:</strong> buscas maximizar ganancias a mediano
                plazo, con una mayor exposición a la renta variable.
              </li>
              <li>
                <strong>Tolerante:</strong> dispuesto a asumir altos riesgos,
                invirtiendo a medio plazo y con una posible asignación del 100%
                en renta variable.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <img
              src={investmentIMG}
              alt="Imagen de Inversión"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <h4>
              Por último, explora diferentes productos financieros para invertir
            </h4>
            <p>Fondos de inversión, Planes de pensiones, Acciones (bolsa).</p>
            <p>Además, considera otras formas de inversión como:</p>
            <ul>
              <li>Bienes raíces</li>
              <li>Bonos</li>
              <li>Crowdfunding inmobiliario</li>
              <li>Materias primas</li>
            </ul>
            <p>¿Listo para empezar a invertir en el mundo financiero?</p>
          </div>
        </div>
      </div>
    </>
  )
}
