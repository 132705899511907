import * as React from 'react'
import { SubscriptionRequeriment } from '../SubscriptionRequeriment/SubscriptionRequeriment'
import { BetaModal } from '../BetaModal/BetaModal'

type ModalOpened = 'none' | 'subscriptionRequeriment' | 'subscriptionOptional'

export interface AlertsContextType {
  setAlertsData: (value) => void
  setModalSubscriptionOpened: (modalOpened: ModalOpened) => void
  setBetaModal: (opened: boolean) => void
}

const AlertsContextInfo: AlertsContextType = {
  setAlertsData: () => undefined,
  setModalSubscriptionOpened: () => null,
  setBetaModal: () => null,
}

export const AlertsContext =
  React.createContext<AlertsContextType>(AlertsContextInfo)

export const AlertsProvider = ({ children }) => {
  const [_alertsData, setAlertsData] = React.useState<any>()
  const [modalSubscriptionOpened, setModalSubscriptionOpened] =
    React.useState<ModalOpened>('none')
  const [betaModal, setBetaModal] = React.useState<boolean>(false)

  return (
    <AlertsContext.Provider
      value={{
        setAlertsData: setAlertsData,
        setModalSubscriptionOpened,
        setBetaModal,
      }}
    >
      <>
        {modalSubscriptionOpened !== 'none' && false && (
          <SubscriptionRequeriment
            isOpen={true}
            onClose={() => setModalSubscriptionOpened('none')}
            forceSubscription={
              modalSubscriptionOpened === 'subscriptionRequeriment'
            }
          />
        )}
        {betaModal && (
          <BetaModal isOpen={true} onClose={() => setBetaModal(false)} />
        )}
        {children}
      </>
    </AlertsContext.Provider>
  )
}
