import * as React from 'react'

export const Cookies = () => {
  return (
    <div className="container pt-5">
      <h1 className="text-center  pt-5">Política de Cookies</h1>

      <div className="section-title">Importante</div>
      <p className="text-muted">
        Puedes eliminar toda la información que creas pertinente de los
        servidores de Google a través de la web:{' '}
        <a href="https://myactivity.google.com/myactivity" target="_blank">
          https://myactivity.google.com/myactivity
        </a>
      </p>

      <div className="section-title">Cumplimiento del RGPD</div>
      <p className="text-muted">
        En cumplimiento con el nuevo Reglamento General de Protección de Datos
        (RGPD) en vigor desde el 25 de mayo de 2018 y con lo dispuesto en el
        artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la
        Sociedad de la Información y de Comercio Electrónico,
        «restauranteabacerialosabuelos.com» (en adelante ‘esta web’) informa, en
        esta sección, de la política de privacidad y tratamiento de los datos
        recogidos.
      </p>
      <p className="text-muted">
        Al entrar en esta web ya has sido informado sobre la utilización de las
        cookies a través de un aviso o alerta. Si has hecho clic en aceptar o
        has seguido navegando, has aceptado la instalación de estas cookies.
      </p>

      <div className="section-title">¿Qué son las Cookies?</div>
      <p className="text-muted">
        Una cookie es un fichero que se descarga en tu ordenador o equipo al
        acceder a determinadas páginas web. Las cookies permiten a una página
        web, entre otras cosas, almacenar y recuperar información sobre los
        hábitos de navegación del usuario o de su equipo y, dependiendo de la
        información que contengan y de la forma en que utilice tu equipo, pueden
        utilizarse para reconocer al usuario.
      </p>

      <div className="section-title">
        ¿Qué tipos de Cookies utiliza esta Página Web?
      </div>
      <p className="text-muted">
        Siguiendo las directrices de la Agencia Española de Protección de Datos
        procedemos a detallar el uso de cookies que hace esta web con el fin de
        informarle con la máxima exactitud posible.
      </p>

      <div className="sub-section">Cookies Propias</div>
      <ul>
        <li>
          <strong>Cookies de sesión:</strong> para garantizar que los usuarios
          que escriban comentarios en el blog sean humanos y no aplicaciones
          automatizadas. De esta forma se combate el spam.
        </li>
      </ul>

      <div className="sub-section">Cookies de Análisis</div>
      <ul>
        <li>
          <strong>Google Analytics, Hotjar:</strong> Son recabadas únicamente
          por terceros y nos permiten cuantificar el número de usuarios y así
          realizar la medición y análisis estadístico de la utilización que
          hacen los usuarios del sitio web. Para ello se analiza su navegación
          en nuestra página web con el fin de mejorar la oferta de productos o
          servicios que te ofrecemos. Puedes consultar cómo eliminar los datos
          recogidos por Hotjar del navegador{' '}
          <a href="#" target="_blank">
            aquí
          </a>
          .
        </li>
      </ul>

      <div className="section-title">Resumen</div>
      <p className="text-muted">
        Utilizamos las cookies únicamente para Google Analytics, redes sociales
        y antispam.
      </p>

      <div className="section-title">
        Desactivación o Eliminación de Cookies
      </div>
      <p className="text-muted">
        En cualquier momento podrás ejercer tu derecho de desactivación o
        eliminación de cookies de este sitio web. Estas acciones se realizan de
        forma diferente en función del navegador que estés usando.
      </p>

      <div className="section-title">
        Esta web NO recoge información personal de los usuarios
      </div>
      <p className="text-muted">
        En cumplimiento con el nuevo Reglamento General de Protección de Datos
        (RGPD) en vigor desde el 25 de mayo de 2018 y con lo dispuesto en el
        artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la
        Sociedad de la Información y de Comercio Electrónico, esta web informa,
        en esta sección, de la política de privacidad y tratamiento de los datos
        recogidos.
      </p>
      <p className="text-muted">
        La única forma de que en esta web se reciba información del usuario es
        por medio del envío, por parte de este mismo usuario, de una
        comunicación vía mail a nuestra dirección de contacto.
      </p>
      <p className="text-muted">
        En ningún supuesto esta web pretende recabar información de los usuarios
        de esta web.
      </p>

      <div className="section-title">Notas Adicionales</div>
      <ul>
        <li>
          Ni esta web ni sus representantes legales se hacen responsables ni del
          contenido ni de la veracidad de las políticas de privacidad que puedan
          tener los terceros mencionados en esta política de cookies.
        </li>
        <li>
          Los navegadores web son las herramientas encargadas de almacenar las
          cookies y desde este lugar debes efectuar tu derecho a eliminación o
          desactivación de las mismas. Ni esta web ni sus representantes legales
          pueden garantizar la correcta o incorrecta manipulación de las cookies
          por parte de los mencionados navegadores.
        </li>
        <li>
          En algunos casos es necesario instalar cookies para que el navegador
          no olvide tu decisión de no aceptación de las mismas.
        </li>
        <li>
          En el caso de las cookies de Google Analytics, esta empresa almacena
          las cookies en servidores ubicados en Estados Unidos y se compromete a
          no compartirla con terceros, excepto en los casos en los que sea
          necesario para el funcionamiento del sistema o cuando la ley obligue a
          tal efecto. Según Google no guarda tu dirección IP. Google Inc. es una
          compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos
          los datos transferidos serán tratados con un nivel de protección
          acorde a la normativa europea.
        </li>
      </ul>
    </div>
  )
}
