import dayjs from 'dayjs'
import { Bill, Income } from '../../../../models/house.models'
import { today } from '../../../../utils/date'

export function sumarMesesAFecha(numero: number, fecha: Date) {
  const fechaActual = new Date(fecha)
  fechaActual.setMonth(fechaActual.getMonth() + numero)

  let nuevoAnio = fechaActual.getFullYear()
  let nuevoMes = fechaActual.getMonth() + 1
  let day = fecha.getDate()
  let nuevaFecha = `${nuevoAnio}-${nuevoMes.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
  return dayjs(nuevaFecha).toDate()
}

export const groupBills = (bills: Bill[]): Bill[] => {
  let billsGroupped = bills.filter((bill) => bill.repetition === 'none')
  const time = {
    weekly: 'controlar en otro object',
    monthly: 1,
    quarter: 3,
    semester: 6,
    annual: 12,
  }
  const timeTypes = ['monthly', 'quarter', 'semester', 'annual']
  timeTypes.forEach((timeType) => {
    const billsOfTheType = bills.filter((bill) => bill.repetition === timeType)
    const typeSpaced = time[timeType]
    billsOfTheType.forEach((billOfType) => {
      billsGroupped.push({ ...billOfType, date: billOfType.startDate })
      let dateToSet: Date = new Date(billOfType.startDate)
      const nextDate = (): Date => sumarMesesAFecha(typeSpaced, dateToSet)

      while (nextDate() < today) {
        const date = nextDate()
        const newBill: Bill = {
          ...billOfType,
          date,
        }
        billsGroupped.push(newBill)

        dateToSet = new Date(date)
      }
    })
  })
  return billsGroupped
}

export const groupIncomes = (incomes: Income[]): Income[] => {
  let incomesGroupped = incomes.filter((bill) => bill.repetition === 'none')
  const time = {
    weekly: 'controlar en otro object',
    monthly: 1,
    quarter: 3,
    semester: 6,
    annual: 12,
  }
  const timeTypes = ['monthly', 'quarter', 'semester', 'annual']
  timeTypes.forEach((timeType) => {
    const incomesOfTheType = incomes.filter(
      (bill) => bill.repetition === timeType,
    )
    const typeSpaced = time[timeType]
    incomesOfTheType.forEach((billOfType) => {
      incomesGroupped.push({ ...billOfType, date: billOfType.startDate })
      let dateToSet: Date = new Date(billOfType.startDate)
      const nextDate = (): Date => sumarMesesAFecha(typeSpaced, dateToSet)

      while (nextDate() < today) {
        const date = nextDate()
        const newBill: Income = {
          ...billOfType,
          date,
        }
        incomesGroupped.push(newBill)

        dateToSet = new Date(date)
      }
    })
  })
  return incomesGroupped
}
