import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js'
import * as React from 'react'
import { Line } from 'react-chartjs-2'
import { useHelmet } from '../../../hooks/useHelmet'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

export const DiferencesBetweenSimpleAndCompoundInterest = () => {
  const tablaDatos = [
    [1, '10.500,00 €', '10.500,00 €', '0,00 €'],
    [5, '12.500,00 €', '12.762,82 €', '262,82 €'],
    [10, '15.000,00 €', '16.288,95 €', '1.288,95 €'],
    [15, '17.500,00 €', '20.789,28 €', '3.289,28 €'],
    [20, '20.000,00 €', '26.532,98 €', '6.532,98 €'],
    [25, '22.500,00 €', '33.863,55 €', '11.363,55 €'],
    [30, '25.000,00 €', '43.219,42 €', '18.219,42 €'],
    [35, '27.500,00 €', '55.160,15 €', '27.660,15 €'],
    [40, '30.000,00 €', '70.399,89 €', '40.399,89 €'],
    [45, '32.500,00 €', '89.850,08 €', '57.350,08 €'],
    [50, '33.000,00 €', '94.342,58 €', '61.342,58 €'],
    [55, '35.000,00 €', '114.674,00 €', '79.674,00 €'],
  ]
  function euroToNumber(euroString) {
    // Eliminar caracteres no numéricos
    const numberString = euroString.replace(/[^\d.,-]/g, '')
    // Reemplazar comas por puntos y convertir a número
    return parseFloat(numberString.replace(',', '.'))
  }

  const simple = []
  const compuesto = []
  const labels = []

  tablaDatos.forEach((fila) => {
    labels.push(fila[0])
    simple.push(euroToNumber(fila[1]))
    compuesto.push(euroToNumber(fila[2]))
  })

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Compuesto',
        data: compuesto,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
      {
        label: 'Simple',
        data: simple,
        fill: false,
        borderColor: 'rgb(165, 192, 75)',
        tension: 0.1,
      },
    ],
  }

  return (
    <>
      {useHelmet({
        title: 'Diferencias entre el Interés Simple y Compuesto',
        description:
          '🔍 Explora las diferencias entre el interés simple y compuesto: descubre cómo afectan tus finanzas y toma decisiones más acertadas 💡.',
        keywords: 'Diferencias entre el Interés Simple y Compuesto',
      })}
      <div className="container">
        <header className="text-center mt-5">
          <h1>
            Diferencias entre el Interés Simple y Compuesto: Características a
            Explorar
          </h1>
        </header>
        <article className="mt-5">
          <section>
            <h2 className="text-center">Resumiendo</h2>
            <p>
              El interés es la tarifa que se paga por el uso del dinero
              prestado, ya sea al solicitar un préstamo o al invertir en una
              entidad bancaria. Dentro de esta categoría, existen dos tipos
              principales: el interés simple y el interés compuesto. Mientras
              que el interés simple se calcula únicamente sobre el monto
              principal, el interés compuesto considera tanto el capital inicial
              como los intereses acumulados. Esto conlleva a tasas de interés
              variables según se trate de interés simple o compuesto.
            </p>
            <p>
              Al solicitar un préstamo, es recomendable buscar entidades con
              tasas de interés más bajas, mientras que como inversor, se buscan
              tasas de interés más altas para maximizar los rendimientos.
            </p>
          </section>
          <section>
            <h2 className="text-center mt-4">Entendiendo el Interés Simple</h2>
            <p>
              El interés simple se refiere al interés calculado únicamente sobre
              el capital prestado durante un período específico. Su fórmula es
              bastante sencilla:
            </p>
            <p>
              <strong>Fórmula del Interés Simple:</strong> P * R * N
            </p>
            <p>(Donde P = Principal, R = Tasa, N = Número de años)</p>
            <p>
              La rentabilidad del interés simple suele ser menor que la del
              interés compuesto.
            </p>
            <p>
              Por ejemplo, si pides prestados 1,000 euros con un interés simple
              del 10% anual durante tres años, al final deberás devolver 1,300
              euros al banco: los 1,000 euros prestados más 300 euros en
              intereses.
            </p>
            <p>
              Continúa leyendo para comprender las diferencias fundamentales
              entre el interés simple y compuesto, lo que te ayudará a tomar
              decisiones financieras más informadas.
            </p>
            <p>
              Si lo que estás buscando es ver la rentabilidad concreta de una
              inversión con interes simple, te recomendamos{' '}
              <a href="/herramientas/calculadora-rentabilidad/interes-simple">
                usar gratis una calculadora de interés simple.
              </a>
            </p>
          </section>
        </article>
        <section>
          <h2 className="text-center mt-4">
            Las Claves de Ambos Tipos de Interés
          </h2>
          <p>
            Básicamente, los intereses son como una tarifa por usar el dinero de
            otra persona o entidad. Las razones para pagar intereses incluyen el
            riesgo, la inflación, el valor temporal del dinero (efecto de la
            capitalización) y el coste de oportunidad. A continuación, puedes
            ver más diferencias entre ambos tipos de interés:
          </p>
          <ul>
            <li>
              <strong>Intereses:</strong> el interés que se cobra es menor
              cuando se utiliza un interés simple, ya que este solo incluye el
              capital inicial. En cambio, si se usa capitalización, como ocurre
              en el interés compuesto, ese interés puede variar según la
              frecuencia con la que se calcula.
            </li>
            <li>
              <strong>Pagos:</strong> para el prestatario es menos costoso
              devolver el dinero si el interés es simple.
            </li>
            <li>
              <strong>Saldos:</strong> el saldo de un préstamo es siempre el
              mismo cuando se utiliza un interés simple; en cambio, con un
              interés compuesto, ese saldo aumenta porque se añaden más
              intereses.
            </li>
          </ul>
          <p>
            En Raisin nos gustaría recordarte que, si tienes varias opciones de
            inversión, debes analizar con detalle cada propuesta para conocer
            sus características y tomar así la decisión más acertada.
          </p>
        </section>

        <section>
          <section>
            <h2 className="text-center mt-4">
              El Efecto Bola de Nieve del Interés Compuesto
            </h2>
            <p>
              El interés compuesto causa lo que se conoce como el efecto bola de
              nieve del interés compuesto. Este nombre compara el interés
              compuesto con una bola de nieve porque el monto sobre el que se
              calculan los intereses se va haciendo más grande. Es decir, al
              igual que una bola de nieve se agranda conforme va cayendo la
              nieve, más rentabilidad obtiene el ahorrador que elige productos
              con interés compuesto.
            </p>
            <p>
              Para que lo entiendas mejor, imagina que inviertes 10.000 € con un
              interés simple del 5 % anual. Si mantienes la inversión durante 20
              años, obtendrás 10.000 € de rentabilidad y el capital acumulado
              será de 20.000 €. En cambio, con un interés compuesto el capital
              total será de 26.532,97 €. Esto es una diferencia de 6.532,97 €. A
              largo plazo, y para importes más elevados, las diferencias son
              todavía mayores:
            </p>
            <div className="table-responsive">
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Año</th>
                    <th>Interés Simple</th>
                    <th>Interés Compuesto</th>
                    <th>Diferencia</th>
                  </tr>
                </thead>
                <tbody>
                  {tablaDatos.map((fila) => (
                    <tr>
                      <td>{fila[0]}</td>
                      <td>{fila[1]}</td>
                      <td>{fila[2]}</td>
                      <td>{fila[3]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <Line data={data} />
          <p>
            Si lo que estás buscando es ver la rentabilidad concreta de una
            inversión con interes compuesto, te recomendamos{' '}
            <a href="/herramientas/calculadora-rentabilidad/interes-compuesto">
              usar gratis una calculadora de interés compuesto.
            </a>
          </p>
        </section>
      </div>
    </>
  )
}
