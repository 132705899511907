import {
  Bill,
  Bills,
  BuyAndSellInfo,
  HouseInfo,
  Income,
  Incomes,
} from '../../models/house.models'
import { groupIncomes } from '../../views/PrivateZone/Houses/HouseDetail/HouseDetail.utils'
import axiosApi, { dataToURLSearchParams } from '../AxiosConfig'
import {
  BillsDomain,
  BuyAndSellInfoDomain,
  HouseInfoDomain,
  IncomesDomain,
} from '../domainModels/houses.models'
import { DefaultRespnse } from '../general/responses.models'
import { camelCaseToSnakeCaseObject } from '../mappers/general.mappers'
import {
  BillModelToBillModelView,
  IncomeModelToIncomeModelView,
  buyAndSellModelToBuyAndSellModelView,
  houseModelToHouseModelView,
} from '../mappers/houses.mappers'

export const getHouses = async (
  idUser: string,
): Promise<{ houses: HouseInfo[] }> => {
  const houses: { houses: HouseInfoDomain[] } = await axiosApi.get(
    'houses/getHousesList',
    dataToURLSearchParams({ idUser }),
  )
  console.log('houses', idUser, houses)
  return {
    houses: houses.houses.map((house) => houseModelToHouseModelView(house)),
  }
}

// TODO: cambiar el json para que las que se repitan tengan fecha inicio sin fin y cambiar la view praa pintarlo bien
export const getBills = async ({
  houseId,
}: {
  houseId: string
}): Promise<Bills> => {
  console.log('houses getbills', houseId)

  const bills: BillsDomain = await axiosApi.get(
    'houses/getBillsList',
    dataToURLSearchParams({ house_id: houseId }),
  )
  console.log('bilss', bills)
  return { bills: bills.bills.map((bill) => BillModelToBillModelView(bill)) }
}
export const getIncomes = async ({
  houseId,
}: {
  houseId: string
}): Promise<Incomes> => {
  console.log('houseId incomes', houseId)
  const incomes: IncomesDomain = await axiosApi.get(
    'houses/getIncomeList',
    dataToURLSearchParams({ house_id: houseId }),
  )
  console.log('bilss', incomes)

  return {
    incomes: incomes.incomes.map((income) =>
      IncomeModelToIncomeModelView(income),
    ),
  }
}

export const getBuyAndSell = async ({
  houseId,
}: {
  houseId: string
}): Promise<BuyAndSellInfo> => {
  console.log('houseId buyAndSellInfo', houseId)

  const buyAndSell: { buyAndSell: BuyAndSellInfoDomain } = await axiosApi.get(
    'houses/buyAndSellInfo',
    dataToURLSearchParams({ house_id: houseId }),
  )
  console.log('buyAndSell', buyAndSell)

  return buyAndSellModelToBuyAndSellModelView(buyAndSell.buyAndSell)
}

/* ------------------save */

export const saveNewHouseService = async (house: HouseInfoDomain) => {
  console.log('house', house)

  const response: { ok: string } = await axiosApi.post(
    'houses/saveNewHouse',
    dataToURLSearchParams(camelCaseToSnakeCaseObject(house)),
  )
  return response
}
export const saveNewBillService = async (bill: Bill) => {
  console.log('billtosave', bill)
  const response: { ok: string } = await axiosApi.post(
    'houses/saveNewBill',
    dataToURLSearchParams(camelCaseToSnakeCaseObject(bill)),
  )
  return response
}

export const saveNewIncomeService = async (income: Income) => {
  console.log('income', income)

  const response: { ok: string } = await axiosApi.post(
    'houses/saveNewIncome',
    dataToURLSearchParams(camelCaseToSnakeCaseObject(income)),
  )
  return response
}

export const saveBuyAndSellInfoService = async (buyAndSell: BuyAndSellInfo) => {
  console.log('buyAndSell', buyAndSell)

  const response: { ok: string } = await axiosApi.post(
    'houses/saveBuyAndSellInfo',
    dataToURLSearchParams(camelCaseToSnakeCaseObject(buyAndSell)),
  )
  return response
}

/* 
  ---    delete
*/

export const deleteHouse = async (id: string) => {
  const response: DefaultRespnse = await axiosApi.post(
    'houses/deleteHouse',
    dataToURLSearchParams(camelCaseToSnakeCaseObject({ id: id })),
  )
  return response
}

/* general economic info */
export const onGetHousesEconomicInfo = async ({
  userId,
}): Promise<{
  houses: HouseInfo[]
  incomesByHouse: {
    houseId: string
    incomes: {
      value: number
      currency: string
    }
  }[]
}> => {
  // Obtener la lista de casas
  const { houses: housesList }: { houses: HouseInfoDomain[] } =
    await axiosApi.get(
      'houses/getHousesList',
      dataToURLSearchParams({ idUser: userId }),
    )

  // Crear un array de promesas para obtener los ingresos de cada casa
  const incomePromises = housesList.map(async (house) => {
    const { incomes }: IncomesDomain = await axiosApi.get(
      'houses/getIncomeList',
      dataToURLSearchParams({ house_id: house.id }),
    )
    let totalIncomes = 0
    groupIncomes(incomes.map(IncomeModelToIncomeModelView)).forEach(
      (income) => (totalIncomes += income.amount.value),
    )
    return {
      houseId: house.id,
      incomes: { value: totalIncomes, currency: house.currency },
    }
  })

  // Esperar a que todas las promesas se resuelvan
  const incomesByHouse = await Promise.all(incomePromises)

  // Devolver los resultados finales
  return {
    houses: housesList.map(houseModelToHouseModelView),
    incomesByHouse,
  }
}
