export const coins = { eur: '€', usd: '$' }

export const getCurrencySymbol = (text: string) => {
  // Expresión regular para capturar el símbolo de la moneda
  const match = text.match(/^[^\d\s,]+/)
  if (match) {
    return match[0]
  }
  return null
}

const detectNumberFormat = (numberStr) => {
  // Definimos un patrón para el formato español (1.234,56)
  const spanishFormat = /^[0-9]{1,3}(\.[0-9]{3})*(,[0-9]{2})?$/
  // Definimos un patrón para el formato inglés (1,234.56)
  const englishFormat = /^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]{2})?$/

  if (spanishFormat.test(numberStr)) {
    return 'es'
  } else if (englishFormat.test(numberStr)) {
    return 'en'
  } else {
    return 'Formato desconocido'
  }
}

export const getNumericValue = (text: string) => {
  // Expresión regular para eliminar cualquier carácter que no sea un número o un punto o coma
  let formattedNumber

  if (detectNumberFormat(text) === 'es') {
    // Reemplazar puntos por nada y comas por puntos
    formattedNumber = text.replace(/\./g, '').replace(',', '.')
  } else {
    // Reemplazar comas por nada
    formattedNumber = text.replace(/,/g, '')
  }
  return isNaN(formattedNumber) ? null : formattedNumber
}
