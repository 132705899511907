import * as React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Field } from '../../views/components/Field'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useService } from '../../hooks/useService'
import { toSubscribeFree } from '../../domain/services/users.service'
import { MainButton } from '../../views/components/Buttons'
import { generateRandomString } from '../../utils/general'

export const SubscriptionRequeriment = ({
  isOpen,
  onClose,
}: /*   forceSubscription, */
{
  isOpen?: boolean
  onClose?: () => void
  forceSubscription?: boolean
}) => {
  /*   const handleShow = () => setShow(true)
   */
  const forceSubscription = false
  const { onExecute, state } = useService({
    callBack: toSubscribeFree,
    executeOnStart: false,
  })
  const emailSaved = localStorage.getItem('email')
  const [show, setShow] = React.useState(!emailSaved && forceSubscription)
  const handleClose = () => {
    setShow(false)
    if (!!onClose) {
      onClose()
    }
  }

  const {
    register,
    trigger,
    control,
    formState: { errors },
    getValues,
  } = useForm<{ email: string }>({
    mode: 'all',
  })

  React.useEffect(() => {
    if (!emailSaved) {
      trigger()
    }
  }, [])

  React.useEffect(() => {
    if (state === 'success') {
      handleClose()
    }
  }, [state])

  const onSave = (email: string) => {
    onExecute({ email, id: generateRandomString(30) })
    localStorage.setItem('email', email)
  }

  if (!!emailSaved && !isOpen) {
    return null
  }

  return (
    <>
      <Modal show={show || isOpen} onHide={handleClose}>
        <Modal.Header closeButton={isOpen}>
          <Modal.Title>Subscripción</Modal.Title>
        </Modal.Header>
        <form onSubmit={null} className="mb-3">
          <Modal.Body>
            <p>
              {isOpen
                ? 'Date de alta en nuestra lista de espera. Te avisaremos mediante un email cuando nuestra herramienta salga al mercado.'
                : 'Subscríbete de forma gratuita para poder utilizar nuestras herramientas gratuitas.'}
            </p>

            <Field control={control} label="Introduce tu email" name="email">
              <Form.Control
                placeholder="Pj: ejemplo@email.com"
                {...register('email', { required: true })}
              />
            </Field>
          </Modal.Body>
          <Modal.Footer>
            <MainButton
              disabled={!!errors.email}
              onClick={() => onSave(getValues().email)}
            >
              Dar de alta
            </MainButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
