import * as React from 'react'
/* import img from '../../assets/chico-leyendo-documentos.jpg'
import imgVoiceTrans from '../../assets/VoiceTransformation.jpg'
import imgYoutube from '../../assets/recordingAudioForYoutube.jpg'
import dictarOnline from '../../assets/DictarOnline.png'
import trascriptor from '../../assets/Transforma tus audios en texto.png'
import iconText from '../../assets/leer-texto-icono.jpg'
 */
import { useHelmet } from '../../hooks/useHelmet'
import { MainA } from '../components/Buttons'

const Blog: React.FC = () => {
  return (
    <>
      {useHelmet({
        title:
          '📰 Artículos de Blog sobre Inversiones: Tu Fuente de Conocimiento Financiero 💡',
        description:
          '📈 Explora nuestra colección de artículos de blog sobre inversiones y descubre consejos, estrategias y tendencias para maximizar tus ganancias y alcanzar tus objetivos financieros. 🚀',
        keywords: 'Blog sobre Inversiones',
      })}

      <main className="mt-5">
        <div
          className="container row justify-content-md-center"
          style={{ gap: '16px' }}
        >
          <h1>Blog The Investor Home</h1>
          <div className="col-md-auto">
            <div className="card" style={{ width: '18rem' }}>
              {/*  <img
                src={img}
                className="card-img-top"
                alt="chico leeryendo texto"
                width="287"
                height="192"
              /> */}
              <div className="card-body">
                <h2 className="card-title" style={{ fontSize: '24px' }}>
                  Letras del tesoro: ¿Que son?
                </h2>
                <p className="card-text">
                  El artículo analiza las letras del Tesoro, destacando su
                  función como instrumentos de inversión de bajo riesgo emitidos
                  por el gobierno. Se resalta su utilidad como opción para
                  diversificar la cartera de inversiones y como alternativa a
                  otros activos financieros. Es una lectura esencial para
                  comprender el papel de las letras del Tesoro en el mercado
                  financiero y considerar su inclusión en estrategias de
                  inversión.
                </p>
                <MainA
                  href="/blog/letras-del-tesoro"
                  className="btn btn-primary"
                >
                  Ver Artículo
                </MainA>
              </div>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="card" style={{ width: '18rem' }}>
              {/*   <img
                src={imgYoutube}
                className="card-img-top"
                alt="lector de texto con voz para youtube"
                height="300"
                width="287"
              /> */}
              <div className="card-body">
                <h2 className="card-title" style={{ fontSize: '24px' }}>
                  Interes simple y compuesto: diferencias
                </h2>
                <p className="card-text">
                  El artículo explora las diferencias entre el interés simple y
                  compuesto, destacando cómo afectan a los préstamos e
                  inversiones. Se discuten las características clave de ambos
                  tipos de interés, así como el efecto bola de nieve del interés
                  compuesto, que puede generar mayores ganancias a largo plazo.
                  Es una lectura útil para comprender estos conceptos
                  financieros y tomar decisiones informadas sobre préstamos e
                  inversiones.
                </p>
                <MainA
                  href="/blog/diferencias-interes-simple-compuesto"
                  className="btn btn-primary"
                >
                  Ver Artículo
                </MainA>
              </div>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="card" style={{ width: '18rem' }}>
              {/*  <img
                src={imgVoiceTrans}
                className="card-img-top"
                alt="cambiar la voz"
                height="300"
                width="287"
              /> */}
              <div className="card-body">
                <h2 className="card-title" style={{ fontSize: '24px' }}>
                  Cómo empezar a invertir
                </h2>
                <p className="card-text">
                  Descubre cómo empezar a invertir con esta guía completa.
                  Aprende a establecer tus metas financieras, identificar tu
                  perfil de inversión y explorar diferentes productos
                  financieros para invertir. ¡Empieza tu viaje en el mundo de
                  las inversiones hoy mismo!
                </p>
                <MainA
                  href="/blog/como-empezar-a-invertir"
                  className="btn btn-primary"
                >
                  Ver Artículo
                </MainA>
              </div>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="card" style={{ width: '18rem' }}>
              {/*  <img
                src={imgVoiceTrans}
                className="card-img-top"
                alt="cambiar la voz"
                height="300"
                width="287"
              /> */}
              <div className="card-body">
                <h2 className="card-title" style={{ fontSize: '24px' }}>
                  Calcular IPC alquileres.
                </h2>
                <p className="card-text">
                  Aprende a calcular el IPC de alquileres de manera rápida y
                  sencilla. Descubre cómo ajustar el importe del alquiler según
                  las fluctuaciones del IPC. Conoce las restricciones y límites
                  para los próximos años.
                </p>
                <MainA
                  href="/blog/calcular-ipc-alquileres"
                  className="btn btn-primary"
                >
                  Ver Artículo
                </MainA>
              </div>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="card" style={{ width: '18rem' }}>
              {/*  <img
                src={imgVoiceTrans}
                className="card-img-top"
                alt="cambiar la voz"
                height="300"
                width="287"
              /> */}
              <div className="card-body">
                <h2 className="card-title" style={{ fontSize: '24px' }}>
                  ¿Qué es Rent To Rent?
                </h2>
                <p className="card-text">
                  El "rent to rent" es una estrategia de inversión inmobiliaria
                  donde un inquilino alquila una propiedad y luego subarrienda
                  habitaciones para obtener ganancias adicionales. Requiere el
                  consentimiento del propietario y ofrece ventajas como una
                  menor inversión inicial y mayor flexibilidad. Sin embargo,
                  también presenta desafíos como la gestión activa de la
                  propiedad y el cumplimiento de obligaciones legales. Es
                  crucial comprender los aspectos legales y desafíos antes de
                  considerar esta estrategia.
                </p>
                <MainA href="/blog/rent-to-rent" className="btn btn-primary">
                  Ver Artículo
                </MainA>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
export default Blog
