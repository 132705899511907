import { Navigate, Outlet } from 'react-router'
import * as React from 'react'
import { SessionContext } from './Context/Session.context'

export const PrivateRoute = ({
  redirectPath = '/login',
  children,
}: {
  redirectPath?: string
  children?: any
}) => {
  const { isLogged } = React.useContext(SessionContext)
  if (!isLogged) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}
