import * as React from 'react'
import { User } from '../../models/users.models'
import { useService } from '../../hooks/useService'
import { getuserInfo } from '../../domain/services/users.service'
import Spinner from 'react-bootstrap/Spinner'
import { identificationSessionKey } from '../../utils/storage'

export type SessionData = User

export interface SessionContextType {
  isLogged: boolean
  sessionData: SessionData
  setSessionData: (sessionData: SessionData, callback?: () => void) => void
  cleanSessionData: () => void
  isClientCurrency: (currency: string) => boolean
}

const SessionContextInfo: SessionContextType = {
  isLogged: false,
  sessionData: null,
  setSessionData: (sessionData: SessionData, callback?: () => void) =>
    undefined,
  cleanSessionData: () => undefined,
  isClientCurrency: () => false,
}

export const SessionContext =
  React.createContext<SessionContextType>(SessionContextInfo)

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = React.useState<SessionData>()
  const [isLogged, setIsLogged] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { onExecute, response } = useService({
    callBack: getuserInfo,
    executeOnStart: false,
  })

  React.useEffect(() => {
    if (!!response) {
      saveSessionInfo(response, () => setIsLoading(false))
    }
  }, [response])

  React.useEffect(() => {
    let identificationSession = sessionStorage.getItem(identificationSessionKey)
    if (!!identificationSession) {
      onExecute(identificationSession)
      return
    }
    setIsLoading(false)
  }, [])

  const saveSessionInfo = async (
    sessionData: SessionData,
    callback?: () => void,
  ) => {
    sessionStorage.setItem(identificationSessionKey, sessionData.identification)
    await setSessionData(sessionData)
    await setIsLogged(true)
    if (callback) {
      await callback()
    }
  }

  const cleanSessionData = () => {
    sessionStorage.removeItem(identificationSessionKey)
    setSessionData(null)
    setIsLogged(false)
  }

  const isClientCurrency = (currencyToCompare: string) => {
    return (
      currencyToCompare.toLowerCase() === sessionData.currency.toLowerCase()
    )
  }

  return (
    <SessionContext.Provider
      value={{
        isLogged,
        sessionData,
        setSessionData: saveSessionInfo,
        cleanSessionData,
        isClientCurrency,
      }}
    >
      {isLoading ? <Spinner animation="border" /> : children}
    </SessionContext.Provider>
  )
}
