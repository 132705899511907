import React from 'react'
import '../../dist/css/style.css'
import { Route, Routes /*  useLocation */ } from 'react-router-dom'
import Blog from './Blog'
import { TreasureLetters } from './components/TreasureLetters'
import { PublicLayout } from '../../components/Layout/PublicLayout'
import { DiferencesBetweenSimpleAndCompoundInterest } from './components/DiferencesBetweenSimpleAndCompoundInterest'
import { BegingToInvest } from './components/BeginToInvest'
import { CalculateRentIPC } from './components/CalculateRentIPC'
import { RentToRent } from './components/RentToRent'
import '../../PublicZone.css'

export const BlogRouter = () => {
  // const { pathname } = useLocation()
  return (
    <PublicLayout>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="/letras-del-tesoro" element={<TreasureLetters />} />
        <Route
          path="/diferencias-interes-simple-compuesto"
          element={<DiferencesBetweenSimpleAndCompoundInterest />}
        />
        <Route path="/como-empezar-a-invertir" element={<BegingToInvest />} />
        <Route path="/calcular-ipc-alquileres" element={<CalculateRentIPC />} />
        <Route path="/rent-to-rent" element={<RentToRent />} />
      </Routes>
    </PublicLayout>
  )
}

export default BlogRouter
