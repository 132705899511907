import styled, { css } from 'styled-components'
import * as React from 'react'
import ButtonBootstrap from 'react-bootstrap/Button'

export const MainButton = styled.button<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #00a09c !important;

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

const ButtonContainer = styled.button<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  color: #fff;
  background-color: #696cff;
  border-color: #696cff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      background-color: #797ab3;
    `}

  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        color: #fff;
        background-color: #5f61e6;
        border-color: #5f61e6;
        transform: translateY(-1px);
      }

      &:focus {
        color: #fff;
        background-color: #5f61e6;
        border-color: #5f61e6;
        transform: translateY(0);
        box-shadow: none;
      }
    `}
`

export const MainA = styled.a<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #00a09c !important;

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

export const SecondaryA = styled.a<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f4bebe !important;

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

export const SecondaryButton = styled.a<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f4bebe !important;

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

export const MainLink = styled.a<{ disabled?: boolean }>`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #00a09c;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`

export const Button: React.FC<{
  children: any
  disabled?: boolean
  className?: any
  type?: any
  onClick?: (e) => void
  variant?: any
}> = ({ children, className, type, ...props }) => {
  const Container = !!type ? ButtonBootstrap : (ButtonContainer as any)
  return (
    <Container className={className} {...props}>
      {children}
    </Container>
  )
}



export const ButtonLink = styled.a`
  display: inline-block;
  font-weight: 400;
  line-height: 1.53;

  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #696cff;
  padding: 0.4375rem 1.25rem;
  font-size: 0.9375rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;

  color: #696cff;

  &:hover {
    color: #fff;
    background-color: #5f61e6;
    border-color: #5f61e6;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);
    transform: translateY(-1px);
  }
`
