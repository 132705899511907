import { isLocalHost } from '../utils/utils'

export const useApiFake = true

export const getBaseUrl = () => {
  return isLocalHost
    ? `http://localhost:3000/apifake/`
    : `https://theinvestorhome.com/api/`
}

export const getExtension = () => (isLocalHost ? `json` : `php`)
