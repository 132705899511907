import { HouseInfo } from '../../models/house.models'
import { SavingsInfo } from '../../models/savings.models'
import { MySecurities } from '../../models/securities.models'
import { GlobalPositionInfo } from './../../models/users.models'

export const getHousesInfo = (housesInfo) => {
  let totalIncomeHouses = 0
  let totalHousesPatrimony = 0
  housesInfo.incomesByHouse.forEach(
    (income) => (totalIncomeHouses += income.incomes.value),
  )
  let totalSellHousesBenefit = 0
  housesInfo.houses
    .filter((house) => !!house.sellPrice)
    .forEach((house) => {
      const benefit = house.sellPrice.value - house.buyPrice.value

      totalSellHousesBenefit += benefit
    })
  housesInfo.houses
    .filter((house) => !house.sellPrice)
    .forEach((house) => {
      totalHousesPatrimony += house.totalPurchaseCosts.value
    })

  return { totalIncomeHouses, totalHousesPatrimony, totalSellHousesBenefit }
}

export const getSecuritiesInfo = (securities: MySecurities) => {
  let totalCurrentlySecurities = 0
  let totalOwnInvestSecurities = 0
  let totalSellSecuritiesBenefit = 0

  securities
    .filter((securitie) => !!securitie.sellPrice)
    .forEach((securitie) => {
      const benefit = securitie.sellPriceInEUR - securitie.buyPriceInEUR

      totalSellSecuritiesBenefit += benefit
    })
  securities
    .filter((securitie) => !securitie.sellPrice)
    .forEach((securitie) => {
      totalOwnInvestSecurities += securitie.buyPriceInEUR
      totalCurrentlySecurities += securitie.currentValueInUserCurrency
    })

  return {
    totalCurrentlySecurities,
    totalOwnInvestSecurities,
    totalSellSecuritiesBenefit,
  }
}

export const gpDomainModelToModel = (
  housesInfo: {
    houses: HouseInfo[]
    incomesByHouse: {
      houseId: string
      incomes: {
        value: number
        currency: string
      }
    }[]
  },
  saving: SavingsInfo,
  securities: MySecurities,
  currency: string,
): GlobalPositionInfo => {
  const { totalIncomeHouses, totalHousesPatrimony, totalSellHousesBenefit } =
    getHousesInfo(housesInfo)
  const {
    totalCurrentlySecurities,
    totalOwnInvestSecurities,
    totalSellSecuritiesBenefit,
  } = getSecuritiesInfo(securities)

  if (true) {
    return {
      savings: saving.savings,
      totalHousesIncomes: {
        value: totalIncomeHouses,
        currency: currency,
      },
      totalHousesPatrimony: {
        value: totalHousesPatrimony,
        currency: currency,
      },
      patrimony: {
        value:
          totalHousesPatrimony +
          totalCurrentlySecurities +
          saving.savings.value,
        currency: currency,
      },
      totalSalaryIncome: {
        value: parseFloat('1000'),
        currency: currency,
      },
      houses: housesInfo.houses.length,
      sellHousesBenefit: {
        value: totalSellHousesBenefit,
        currency: currency,
      },
      sellSecuritieBenefit: {
        value: totalSellSecuritiesBenefit,
        currency: currency,
      },
      securitieCurrentlyInfo: {
        personalInvest: {
          value: totalOwnInvestSecurities,
          currency: currency,
        },
        currentlyAmount: {
          value: totalCurrentlySecurities,
          currency: currency,
        },
      },
      fixedRentCurrentlyInfo: {
        personalInvest: {
          value: 0,
          currency: currency,
        },
        currentlyAmount: {
          value: 0,
          currency: currency,
        },
      },
    }
  }

  /*   incomesCurrentYear: {
      januarySalary: {
        value: parseFloat(gpDomain.incomes_current_year.january_salary),
        currency: currency,
      },
      januaryHouses: {
        value: parseFloat(gpDomain.incomes_current_year.january_houses),
        currency: currency,
      },
      februarySalary: {
        value: parseFloat(gpDomain.incomes_current_year.february_salary),
        currency: currency,
      },
      februaryHouses: {
        value: parseFloat(gpDomain.incomes_current_year.february_houses),
        currency: currency,
      },
      marchSalary: {
        value: parseFloat(gpDomain.incomes_current_year.march_salary),
        currency: currency,
      },
      marchHouses: {
        value: parseFloat(gpDomain.incomes_current_year.march_houses),
        currency: currency,
      },
      aprilSalary: {
        value: parseFloat(gpDomain.incomes_current_year.april_salary),
        currency: currency,
      },
      aprilHouses: {
        value: parseFloat(gpDomain.incomes_current_year.april_houses),
        currency: currency,
      },
      maySalary: {
        value: parseFloat(gpDomain.incomes_current_year.may_salary),
        currency: currency,
      },
      mayHouses: {
        value: parseFloat(gpDomain.incomes_current_year.may_houses),
        currency: currency,
      },
      juneSalary: {
        value: parseFloat(gpDomain.incomes_current_year.june_salary),
        currency: currency,
      },
      juneHouses: {
        value: parseFloat(gpDomain.incomes_current_year.june_houses),
        currency: currency,
      },
      julySalary: {
        value: parseFloat(gpDomain.incomes_current_year.july_salary),
        currency: currency,
      },
      julyHouses: {
        value: parseFloat(gpDomain.incomes_current_year.july_houses),
        currency: currency,
      },
      augustSalary: {
        value: parseFloat(gpDomain.incomes_current_year.august_salary),
        currency: currency,
      },
      augustHouses: {
        value: parseFloat(gpDomain.incomes_current_year.august_houses),
        currency: currency,
      },
      septemberSalary: {
        value: parseFloat(gpDomain.incomes_current_year.september_salary),
        currency: currency,
      },
      septemberHouses: {
        value: parseFloat(gpDomain.incomes_current_year.september_houses),
        currency: currency,
      },
      octoberSalary: {
        value: parseFloat(gpDomain.incomes_current_year.october_salary),
        currency: currency,
      },
      octoberHouses: {
        value: parseFloat(gpDomain.incomes_current_year.october_houses),
        currency: currency,
      },
      novemberSalary: {
        value: parseFloat(gpDomain.incomes_current_year.november_salary),
        currency: currency,
      },
      novemberHouses: {
        value: parseFloat(gpDomain.incomes_current_year.november_houses),
        currency: currency,
      },
      decemberSalary: {
        value: parseFloat(gpDomain.incomes_current_year.december_salary),
        currency: currency,
      },
      decemberHouses: {
        value: parseFloat(gpDomain.incomes_current_year.december_houses),
        currency: currency,
      },
    },
    incomesPastYear: {
      januarySalary: {
        value: parseFloat(gpDomain.incomes_past_year.january_salary),
        currency: currency,
      },
      januaryHouses: {
        value: parseFloat(gpDomain.incomes_past_year.january_houses),
        currency: currency,
      },
      februarySalary: {
        value: parseFloat(gpDomain.incomes_past_year.february_salary),
        currency: currency,
      },
      februaryHouses: {
        value: parseFloat(gpDomain.incomes_past_year.february_houses),
        currency: currency,
      },
      marchSalary: {
        value: parseFloat(gpDomain.incomes_past_year.march_salary),
        currency: currency,
      },
      marchHouses: {
        value: parseFloat(gpDomain.incomes_past_year.march_houses),
        currency: currency,
      },
      aprilSalary: {
        value: parseFloat(gpDomain.incomes_past_year.april_salary),
        currency: currency,
      },
      aprilHouses: {
        value: parseFloat(gpDomain.incomes_past_year.april_houses),
        currency: currency,
      },
      maySalary: {
        value: parseFloat(gpDomain.incomes_past_year.may_salary),
        currency: currency,
      },
      mayHouses: {
        value: parseFloat(gpDomain.incomes_past_year.may_houses),
        currency: currency,
      },
      juneSalary: {
        value: parseFloat(gpDomain.incomes_past_year.june_salary),
        currency: currency,
      },
      juneHouses: {
        value: parseFloat(gpDomain.incomes_past_year.june_houses),
        currency: currency,
      },
      julySalary: {
        value: parseFloat(gpDomain.incomes_past_year.july_salary),
        currency: currency,
      },
      julyHouses: {
        value: parseFloat(gpDomain.incomes_past_year.july_houses),
        currency: currency,
      },
      augustSalary: {
        value: parseFloat(gpDomain.incomes_past_year.august_salary),
        currency: currency,
      },
      augustHouses: {
        value: parseFloat(gpDomain.incomes_past_year.august_houses),
        currency: currency,
      },
      septemberSalary: {
        value: parseFloat(gpDomain.incomes_past_year.september_salary),
        currency: currency,
      },
      septemberHouses: {
        value: parseFloat(gpDomain.incomes_past_year.september_houses),
        currency: currency,
      },
      octoberSalary: {
        value: parseFloat(gpDomain.incomes_past_year.october_salary),
        currency: currency,
      },
      octoberHouses: {
        value: parseFloat(gpDomain.incomes_past_year.october_houses),
        currency: currency,
      },
      novemberSalary: {
        value: parseFloat(gpDomain.incomes_past_year.november_salary),
        currency: currency,
      },
      novemberHouses: {
        value: parseFloat(gpDomain.incomes_past_year.november_houses),
        currency: currency,
      },
      decemberSalary: {
        value: parseFloat(gpDomain.incomes_past_year.december_salary),
        currency: currency,
      },
      decemberHouses: {
        value: parseFloat(gpDomain.incomes_past_year.december_houses),
        currency: currency,
      },
    }, */
}
