import { SavingsInfo } from '../../models/savings.models'
import { SavingsInfo as SavingsInfoDomain } from './../domainModels/savings.models'

export const SavingDTOModelView = (
  savingInfo: SavingsInfoDomain,
): SavingsInfo => {
  const { currency, ...rest } = savingInfo

  const prueba = {
    savings: { value: parseFloat(rest.savings), currency },
    estimatedSavingMonth: {
      value: parseFloat(rest.estimated_saving_month),
      currency,
    },
    idUser: rest.idUser,
    idSaving: rest.idSaving,
  }

  return prueba
}
