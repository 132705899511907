import * as React from 'react'

export class ErrorBoundary extends React.Component<{
  children: any
  fallback?: boolean
}> {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.log('error', error, info)
    /*   logErrorToMyService(error, info.componentStack) */
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}
