import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import '../dist/css/style.css'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import logo from '../assets/logo/logo.svg'

import { isFeatureEnabled } from '../utils/feature/features.utils'
import { featureFlags } from '../utils/feature/featureflags'
import { MainButton } from '../views/components/Buttons'
import { AlertsContext } from './Context/Alerts.context'

export const NavBar = () => {
  const navigate = useNavigate()
  const { setModalSubscriptionOpened } = React.useContext(AlertsContext)
  const onSignUp = () => {
    if (isFeatureEnabled(featureFlags.signUp)) {
      navigate('/dar-de-alta')
      return
    }
    setModalSubscriptionOpened('subscriptionOptional')
  }

  return (
    <Navbar key={'md'} expand={'md'} className="bg-body-tertiary mb-3">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} width={'130px'} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-'md'-${'md'}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-'md'-${'md'}`}
          aria-labelledby={`offcanvasNavbarLabel-'md'-${'md'}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <img src={logo} width={'130px'} />
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-'md'-${'md'}`}
            ></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {/*   <Nav.Link href="#action1">Servicios</Nav.Link>
              <Nav.Link href="#action2">Precios</Nav.Link> */}
              <Nav.Link href="/blog">Blog</Nav.Link>
              <Nav.Link href="/herramientas">Herramientas</Nav.Link>
              {/*   <Nav.Link href="#action2">Preguntas frecuentes</Nav.Link> */}
            </Nav>

            {isFeatureEnabled(featureFlags.signUp) && (
              <Button
                variant="secondary"
                className="mx-4"
                onClick={() => navigate('/login')}
              >
                Acceder
              </Button>
            )}
            <MainButton onClick={() => onSignUp()}>Registarme</MainButton>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
