export const snakeCaseToCamelCase = (object: Object) => {
  let newObject = {}
  function snakeToCamel(str) {
    return str.replace(/_([a-z])/g, function (match, letter) {
      return letter.toUpperCase()
    })
  }
  Object.keys(object).map((key) => {
    newObject[snakeToCamel(key)] = object[key]
  })
  return newObject
}

export const camelCaseToSnakeCaseObject = (object: Object) => {
  let newObject = {}
  function camelCaseToSnakeCase(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
  }
  const getKey = (key) => {
    if (typeof object[key] === 'boolean') {
      return object[key].toString()
    }
    return object[key]
  }
  Object.keys(object).map((key) => {
    newObject[camelCaseToSnakeCase(key)] = getKey(key)
  })

  return newObject
}
