import { Card } from 'react-bootstrap'
/* import "../PrivateZone/css/pages/page-auth.css"; */
import {
  AuthenticationWrapper,
  Body,
  ContainerXxl,
  AuthenticationInner,
  CardStyled,
  AppBrand,
} from '../Login.styles'
import { Button } from '../../components/Buttons'
import * as React from 'react'

export const ForgotPassword = () => {
  return (
    <>
      {/*   <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0"
    />

    <title>Forgot Password Basic - Pages | Sneat - Bootstrap 5 HTML Admin Template - Pro</title>

    <meta name="description" content="" />

    <!-- Favicon -->
    <link rel="icon" type="image/x-icon" href="../assets/img/favicon/favicon.ico" />

    <!-- Fonts -->
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
      rel="stylesheet"
    />

    <!-- Icons. Uncomment required icon fonts -->
    <link rel="stylesheet" href="../assets/vendor/fonts/boxicons.css" />

    <!-- Core CSS -->
    <link rel="stylesheet" href="../assets/vendor/css/core.css" className="template-customizer-core-css" />
    <link rel="stylesheet" href="../assets/vendor/css/theme-default.css" className="template-customizer-theme-css" />
    <link rel="stylesheet" href="../assets/css/demo.css" />

    <!-- Vendors CSS -->
    <link rel="stylesheet" href="../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />

    <!-- Page CSS -->
    <!-- Page -->
    <link rel="stylesheet" href="../assets/vendor/css/pages/page-auth.css" />
    <!-- Helpers -->
    <script src="../assets/vendor/js/helpers.js"></script>

    <!--! Template customizer & Theme config files MUST be included after core stylesheets and helpers.js in the <head> section -->
    <!--? Config:  Mandatory theme config file contain global vars & default theme options, Set your preferred theme option in this file.  -->
    <script src="../assets/js/config.js"></script>
  </head>
 */}
      <Body>
        <ContainerXxl>
          <AuthenticationWrapper className="container-p-y">
            <AuthenticationInner>
              <CardStyled>
                <Card.Body>
                  <AppBrand className="justify-content-center">
                    <a href="/" className="app-brand-link gap-2">
                      <span className="app-brand-text demo text-body fw-bolder">
                        Sneat
                      </span>
                    </a>
                  </AppBrand>
                  <h4 className="mb-2">Forgot Password? 🔒</h4>
                  <p className="mb-4">
                    Enter your email and we'll send you instructions to reset
                    your password
                  </p>
                  <form
                    id="formAuthentication"
                    className="mb-3"
                    action="/"
                    method="POST"
                  >
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        /*       autofocus */
                      />
                    </div>
                    <Button className="d-grid w-100">Send Reset Link</Button>
                  </form>
                  <div className="text-center">
                    <a
                      href="auth-login-basic.html"
                      className="btn btn-outline-dark d-grid w-100"
                    >
                      <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                      Back to login
                    </a>
                  </div>
                </Card.Body>
              </CardStyled>
            </AuthenticationInner>
          </AuthenticationWrapper>
        </ContainerXxl>

        {/*       <script src="../assets/vendor/libs/jquery/jquery.js"></script>
        <script src="../assets/vendor/libs/popper/popper.js"></script>
        <script src="../assets/vendor/js/bootstrap.js"></script>
        <script src="../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>

        <script src="../assets/vendor/js/menu.js"></script>

        <script src="../assets/js/main.js"></script>

        <script async defer src="https://buttons.github.io/buttons.js"></script> */}
      </Body>
    </>
  )
}
