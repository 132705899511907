import * as React from 'react'
import { NavBar } from '../NavBar'
import { ErrorBoundary } from '../ErrorBoundary'

export const PublicLayout: React.FC<{
  headerContent?: any
  children?: any
}> = ({ headerContent, children }) => {
  return (
    <body>
      <NavBar />
      <section style={{ marginTop: '40px' }}>
        <ErrorBoundary>
          <React.Suspense
            fallback={<div className="Spinner">{/* <Spinner /> */}</div>}
          >
            <div className="container">
              {children}
              {/*       <Footer /> */}
            </div>
          </React.Suspense>
        </ErrorBoundary>
      </section>
    </body>
  )
}
