import * as React from 'react'
import Toast from 'react-bootstrap/Toast'

export interface SnackbarContextType {
  setSnackbarData: (value) => void
}

const SnackbarContextInfo: SnackbarContextType = {
  setSnackbarData: () => undefined,
}

export const SnackbarContext =
  React.createContext<SnackbarContextType>(SnackbarContextInfo)

export const SnackbarProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = React.useState<{
    type: 'error' | 'info'
    text: string
  }>(null)

  return (
    <SnackbarContext.Provider
      value={{
        setSnackbarData: setSnackbarData,
      }}
    >
      <>
        {snackbarData && (
          <Toast
            onClose={() => setSnackbarData(null)}
            show={!!snackbarData?.text}
            bg={snackbarData.type === 'error' ? 'danger' : 'primary'}
            style={{
              position: 'fixed',
              zIndex: '100',
              width: '100%',
              padding: '10px',
            }}
            /*             delay={3000} */
            /*     autohide */
          >
            {snackbarData.type === 'error' ? (
              <>
                <Toast.Header
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <strong className="me-auto">Ha ocurrido un error</strong>
                </Toast.Header>
                <Toast.Body>{snackbarData.text}</Toast.Body>
              </>
            ) : (
              <>
                <Toast.Header
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <span>{snackbarData.text}</span>
                </Toast.Header>
              </>
            )}
          </Toast>
        )}
        {children}
      </>
    </SnackbarContext.Provider>
  )
}
