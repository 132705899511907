import {
  DividendReceived,
  SecuritieInfoModel,
  SecuritieInfoOperation,
  SecuritiesHistoricPrices,
} from '../../models/securities.models'
import { englishFormatToDate } from '../../utils/date'
import {
  DividendReceivedDomain,
  SecuritieInfoDomain,
  SecuritieInfoOperationDomain,
  SecuritiesHistoricPricesDomain,
} from '../domainModels/securities.models'
import { snakeCaseToCamelCase } from './general.mappers'

export const securitieDomainToModel = (
  securityOperation: SecuritieInfoDomain,
): SecuritieInfoModel => {
  return {
    ...(snakeCaseToCamelCase(securityOperation) as SecuritieInfoModel),
    dividentPercentage: parseFloat(securityOperation.dividend_percentage),
  }
}

export const securitieOperationDomainToModel = (
  securityOperation: SecuritieInfoOperationDomain,
): SecuritieInfoOperation => {
  return {
    ...(snakeCaseToCamelCase(securityOperation) as SecuritieInfoOperation),
    shares: parseFloat(securityOperation.shares),
    buyDate: englishFormatToDate(securityOperation.buy_date),
    buyPrice: parseFloat(securityOperation.buy_price),
    sellDate: englishFormatToDate(securityOperation.sell_date),
    sellPrice: parseFloat(securityOperation.sell_price),
  }
}

export const securitieHistoricPrice = (
  historic: SecuritiesHistoricPricesDomain,
): SecuritiesHistoricPrices => {
  const groupedData = historic.reduce((acc, obj) => {
    const key = obj.id_securitie
    const date = new Date(obj.date) // Convertir la cadena de fecha a un objeto Date
    const price = parseFloat(obj.price)
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push({ price, date }) // Agregar la propiedad date al objeto agrupado
    return acc
  }, {})

  return groupedData as SecuritiesHistoricPrices
}

export const dividendReceivedDomainToModel = (
  securityOperation: DividendReceivedDomain,
): DividendReceived => {
  return {
    ...(snakeCaseToCamelCase(securityOperation) as DividendReceived),
    amount: parseFloat(securityOperation.amount),
    date: englishFormatToDate(securityOperation.date),
  }
}
