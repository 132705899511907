import { SavingsInfo } from '../../models/savings.models'
import axiosApi, { dataToURLSearchParams } from '../AxiosConfig'
import { SavingsInfo as SavingsInfoDomain } from '../domainModels/savings.models'
import { SavingDTOModelView } from '../mappers/savings.mappers'

export const getSaving = async (idUser: string): Promise<SavingsInfo> => {
  const response: { saving: SavingsInfoDomain } = await axiosApi.get(
    'saving/getSaving',
    dataToURLSearchParams({ idUser }),
  )

  return SavingDTOModelView(response.saving)
}
