import React from 'react'
import { DefaultRespnse } from '../domain/general/responses.models'

export type ServiceState = 'waiting' | 'pending' | 'success' | 'error'

export const useService = <T>({
  callBack,
  executeOnStart = true,
  params,
  onSuccess,
}: {
  callBack: (param?: any) => Promise<T>
  executeOnStart?: boolean
  params?: {}
  onSuccess?: (result) => void
}): { response: T; state: ServiceState; onExecute: any } => {
  const [response, setResponse] = React.useState<any>('')
  const [state, setState] = React.useState<ServiceState>('waiting')

  const getInfo = async (params?: {}) => {
    try {
      setState('pending')
      const result = await callBack(params)

      if (
        (result as DefaultRespnse).type === 'error' ||
        (result as DefaultRespnse).type === 'notControlledError'
      ) {
        setState('error')
      } else {
        onSuccess?.(result)
        setState('success')
      }
      setResponse(result)
      return result
    } catch (error) {
      setState('error')
      return null
    } finally {
      /*       if (!isUndefined(this.onAfterCallback)) {
              this.onAfterCallback()
            } */
    }
  }

  const onExecute = async (params?: {}) => await getInfo(params)

  React.useEffect(() => {
    if (executeOnStart) {
      getInfo(params)
    }
  }, [])

  return { response, state, onExecute }
}
