import * as React from 'react'
import { SessionContext } from '../../../../../components/Context/Session.context'
import { ServiceState, useService } from '../../../../../hooks/useService'
import { onGetGPInfo } from '../../../../../domain/services/globalPosition.service'
import { GlobalPositionInfo } from '../../../../../models/users.models'

export interface GlobalPositionContextData {
  globalInfo: GlobalPositionInfo
  setGlobalInfo: (globalInfo: GlobalPositionInfo) => void
  proccessState: ServiceState
  getGlobalInfo: () => void
}

const globalpositionContextInfo: GlobalPositionContextData = {
  setGlobalInfo: () => undefined,
  globalInfo: null,
  proccessState: 'waiting',
  getGlobalInfo: () => undefined,
}

export const GlobalPositionContext =
  React.createContext<GlobalPositionContextData>(globalpositionContextInfo)

export const GlobalPositionProvider = ({ children }) => {
  const [globalInfo, setGlobalInfo] = React.useState<GlobalPositionInfo>()
  const { sessionData } = React.useContext(SessionContext)

  const { state, response, onExecute } = useService<GlobalPositionInfo>({
    callBack: () => onGetGPInfo(sessionData),
    executeOnStart: true,
    params: sessionData,
  })

  React.useEffect(() => {
    if (state === 'success') {
      setGlobalInfo(response)
    }
  }, [response])

  return (
    <GlobalPositionContext.Provider
      value={{
        globalInfo: globalInfo,
        setGlobalInfo: (info) => setGlobalInfo(info),
        proccessState: state,
        getGlobalInfo: onExecute,
      }}
    >
      {children}
    </GlobalPositionContext.Provider>
  )
}
