import * as React from 'react'

export const LegalAdvice = () => {
  return (
    <div className="container pt-5">
      <h1 className="text-center  pt-5">Aviso Legal</h1>

      <div className="section-title">
        Contacto con el Responsable del Sitio Web
      </div>
      <p className="text-muted">
        Puedes contactarnos remitiéndonos un correo electrónico a{' '}
        <a href="mailto:direccion@theinvestorhome.com">
          direccion@theinvestorhome.com
        </a>
      </p>

      <div className="section-title">Objeto</div>
      <p className="text-muted">
        El presente AVISO LEGAL regula los derechos y obligaciones de
        theinvestorhome.com (en adelante ‘la empresa’) y de los usuarios, en
        relación al acceso, navegación y utilización de la presente web sin
        perjuicio de que theinvestorhome.com se reserve el derecho a modificar,
        sin previo aviso, su contenido, siendo vigentes las que se encuentren
        publicadas en el momento de navegación.
      </p>
      <p className="text-muted">
        Mediante la navegación, visualización y uso de esta web adquieres la
        condición de usuario, y por tanto implica por tu parte la aceptación
        expresa de todas las cláusulas que indicamos en el presente Aviso Legal,
        así como el resto de advertencias o cláusulas específicas que se
        establezcan en las diferentes secciones para la contratación, uso de
        servicios, productos, inscripciones, o secciones de la Página.
      </p>
      <p className="text-muted">
        Por lo que en caso de no aceptar las cláusulas establecidas en el
        presente Aviso Legal, deberás abstenerte de acceder y/o utilizar los
        servicios y/o contenidos puestos a tu disposición en la Página,
        procediendo a abandonar la misma.
      </p>
      <p className="text-muted">
        Esta web podrá dar por terminado, suspender o interrumpir, en cualquier
        momento sin necesidad de previo aviso, el acceso a los contenidos de la
        página, sin posibilidad por parte del Usuario de exigir indemnización
        alguna.
      </p>

      <div className="section-title">Servicios</div>
      <p className="text-muted">
        A través del Sitio Web, la EMPRESA se ofrece a los Usuarios acceder a:
      </p>
      <ul>
        <li>
          Información sobre la empresa, sus datos de contacto, sus productos y
          servicios, sus tarifas, sus ofertas comerciales, su ubicación
        </li>
        <li>
          Un apartado de contacto para realizar consultas facilitando sus datos
          de carácter personal
        </li>
        <li>
          Links para acceder a redes sociales (en adelante los “Servicios”).
        </li>
        <li>Posibilidad de realizar reserva de clases.</li>
      </ul>

      <div className="section-title">Acceso al sitio Web</div>
      <p className="text-muted">
        Como usuario de nuestra web te obligas a no utilizar la Página para la
        realización de actividades contrarias a las leyes, a la moral, al orden
        público y, en general, a hacer un uso conforme a las condiciones
        establecidas en el presente Aviso Legal. Asimismo, te obliga a no
        realizar actividades publicitarias o de explotación comercial remitiendo
        mensajes que utilicen una identidad falsa.
      </p>
      <p className="text-muted">
        Esta web actúa exclusivamente como responsable de la Página en su
        condición de prestador de un servicio de información y contenidos, bien
        sobre sus propios servicios, o bien sobre servicios de terceros con los
        que ha suscrito acuerdos, no haciéndose responsable de los contenidos
        que, en contra de las presentes condiciones, los usuarios pudieran
        enviar o publicar, siendo el usuario el único responsable de la
        veracidad y licitud de los mismos.
      </p>
      <p className="text-muted">
        Esta web podrá interrumpir el servicio de la Página que esté siendo
        utilizado por el usuario y resolver de modo inmediato la relación con el
        usuario si detecta un uso de la Página o de cualquiera de los servicios
        que en el mismo se ofertan que pueda considerar contrario a lo expresado
        en el presente Aviso Legal.
      </p>
      <p className="text-muted">
        El contenido de los servicios ofrecidos y su uso quedan limitados a
        usuarios mayores de 18 años. Esta web recuerda a los usuarios mayores de
        edad, que tengan a su cargo menores, que será de su exclusiva
        responsabilidad determinar qué servicios y/o contenidos son no
        apropiados para la edad de estos últimos. En caso de que tenga lugar el
        acceso y registro por parte de un menor, se presumirá que dicho acceso
        se ha realizado con autorización previa y expresa de sus padres, tutores
        o representantes legales, esta web se reserva el derecho a realizar
        cuantas verificaciones y comprobaciones considere oportunas a este
        respecto.
      </p>
      <p className="text-muted">
        Esta web te informa de que existen programas informáticos que permiten
        filtrar y bloquear el acceso a determinados contenidos y servicios, de
        tal forma que los padres o tutores, por ejemplo, pueden decidir cuáles
        son los contenidos y servicios de Internet a los que los menores pueden
        tener acceso y cuáles no.
      </p>
      <p className="text-muted">
        En ningún caso esta web se responsabilizará de la veracidad de los datos
        facilitados por los usuarios, por lo que cada uno de éstos será el único
        responsable de que la información facilitada a esta web resulte
        adecuada, exacta, actualizada y precisa o, en caso contrario, de las
        posibles consecuencias que pudieran derivarse de la falta de calidad y
        veracidad de los datos.
      </p>

      <div className="section-title">Propiedad intelectual e industrial</div>
      <p className="text-muted">
        El Usuario reconoce y acepta que todos los elementos mostrados en el
        Sitio Web, como diseños, textos, imágenes, logos, iconos, botones,
        software, nombres comerciales, marcas, y otros similares, están
        protegidos por derechos de Propiedad Intelectual. La EMPRESA y/o
        terceros son los únicos titulares de estos derechos y tienen
        exclusividad para utilizarlos en actividades comerciales. En este
        sentido, el Usuario se compromete a no reproducir, copiar, distribuir,
        poner a disposición del público, transformar o modificar estos
        elementos, exonerando a la EMPRESA de cualquier reclamación derivada de
        esta acción. El acceso al Sitio Web no implica la renuncia o cesión de
        estos derechos, salvo indicación expresa. Las Condiciones Generales de
        Uso del Sitio Web no otorgan al Usuario ningún otro derecho sobre estos
        elementos, salvo los expresamente mencionados. Cualquier otro uso
        requerirá la autorización previa de la EMPRESA o de los terceros
        titulares.
      </p>
      <p className="text-muted">
        Los contenidos, textos, fotografías, diseños, logotipos, imágenes,
        programas de ordenador, códigos fuente y cualquier otra creación
        intelectual del Sitio Web están protegidos por derechos de autor. La
        EMPRESA posee estos elementos o cuenta con la autorización para su uso.
        Queda prohibida la reproducción, transmisión o registro de estos
        contenidos sin la autorización previa de la entidad. Además, no se
        permite alterar o eliminar los dispositivos de protección o «copyright».
        El Usuario se compromete a respetar estos derechos y la EMPRESA se
        reserva el derecho de tomar medidas legales para protegerlos.
      </p>

      <div className="section-title">Hipervínculos</div>
      <p className="text-muted">
        El Usuario se compromete a no replicar de ninguna manera, ni siquiera
        mediante enlaces o hipervínculos, el Sitio Web de la EMPRESA ni ninguno
        de sus contenidos, a menos que cuente con una autorización expresa y por
        escrito de la EMPRESA. El Sitio Web de la EMPRESA contiene enlaces a
        otros sitios web gestionados por terceros, con el fin de facilitar el
        acceso del Usuario a la información de empresas colaboradoras y/o
        patrocinadoras. Por lo tanto, la EMPRESA no asume responsabilidad alguna
        por el contenido de esos sitios web ni se posiciona como garante o
        proveedor de los servicios y/o información que puedan ofrecerse a
        terceros a través de los enlaces de terceros.
      </p>
      <p className="text-muted">
        Se otorga al Usuario un derecho limitado, revocable y no exclusivo para
        crear enlaces a la página principal del Sitio Web exclusivamente para
        uso privado y no comercial. Los sitios web que enlacen con nuestro Sitio
        Web: (i) no deben dar a entender que la EMPRESA respalda ese sitio web o
        sus servicios o productos; (ii) no deben distorsionar su relación con la
        EMPRESA ni afirmar que la EMPRESA ha autorizado dicho enlace, ni incluir
        marcas, nombres comerciales, logotipos u otros signos distintivos de la
        EMPRESA; (iii) no deben incluir contenidos que puedan considerarse de
        mal gusto, obscenos, ofensivos, controvertidos, que inciten a la
        violencia o la discriminación por razón de sexo, raza o religión,
        contrarios al orden público o ilícitos; (iv) no deben enlazar a ninguna
        página del Sitio Web que no sea la página principal; (v) deben enlazar
        con la dirección propia del Sitio Web, sin permitir que el sitio web que
        realiza el enlace reproduzca el Sitio Web como parte de su propia web o
        dentro de uno de sus marcos, ni crear un navegador sobre ninguna de las
        páginas del Sitio Web. La EMPRESA puede solicitar, en cualquier momento,
        la eliminación de cualquier enlace al Sitio Web, tras lo cual debe
        proceder de inmediato a su eliminación. La EMPRESA no puede controlar la
        información, contenidos, productos o servicios proporcionados por otros
        sitios web que tengan enlaces al Sitio Web.
      </p>

      <div className="section-title">Protección de datos</div>
      <p className="text-muted">
        Para utilizar algunos de los Servicios, los Usuarios deben proporcionar
        ciertos datos personales. La EMPRESA cumplirá con lo establecido en la
        normativa de protección de datos para tratar estos datos, así como en la
        Política de Privacidad que el Usuario debe aceptar previamente para
        proporcionar los datos personales. La EMPRESA adopta medidas para
        garantizar la seguridad de los datos personales de los Usuarios, y
        cuenta con la confidencialidad y el tratamiento adecuado de los mismos
        en todo momento. Para más información, puedes consultar nuestra{' '}
        <a href="#">Política de Privacidad</a>.
      </p>

      <div className="section-title">Cookies</div>
      <p className="text-muted">
        Esta web utiliza cookies para mejorar la experiencia del Usuario, para
        información sobre el uso de cookies, puedes consultar nuestra{' '}
        <a href="#">Política de Cookies</a>.
      </p>

      <div className="section-title">Duración y modificación</div>
      <p className="text-muted">
        La EMPRESA puede modificar los términos y condiciones estipulados aquí,
        total o parcialmente, publicando cualquier cambio de la misma forma en
        que aparece este Aviso Legal o a través de cualquier tipo de
        comunicación dirigida a los Usuarios.
      </p>
      <p className="text-muted">
        La vigencia temporal de este Aviso Legal coincide, por lo tanto, con el
        tiempo de su exposición, hasta que sea modificado total o parcialmente,
        momento en el cual pasará a tener vigencia el Aviso Legal modificado.
      </p>
      <p className="text-muted">
        Independientemente de lo dispuesto en las condiciones particulares, la
        EMPRESA puede dar por terminado, suspender o interrumpir, en cualquier
        momento y sin necesidad de preaviso, el acceso a los contenidos de la
        página, sin posibilidad por parte del Usuario de exigir indemnización
        alguna.
      </p>

      <div className="section-title">Generalidades</div>
      <p className="text-muted">
        Los encabezados de las distintas cláusulas son sólo informativos, y no
        afectarán, calificarán o ampliarán la interpretación del Aviso Legal.
      </p>
      <p className="text-muted">
        En caso de existir discrepancia entre lo establecido en este Aviso Legal
        y las condiciones particulares de cada servicio específico, prevalecerá
        lo dispuesto en estas últimas.
      </p>
      <p className="text-muted">
        En caso de que cualquier disposición de este Aviso Legal sea considerada
        nula o inaplicable, total o parcialmente, por cualquier Juzgado,
        Tribunal u órgano administrativo competente, la nulidad o inaplicación
        no afectará a las restantes disposiciones del Aviso Legal.
      </p>
      <p className="text-muted">
        El no ejercicio o ejecución por parte de la EMPRESA de cualquier derecho
        o disposición contenida en este Aviso Legal no constituirá una renuncia
        al mismo, salvo reconocimiento y acuerdo por escrito por su parte.
      </p>

      <div className="section-title">
        Legislación aplicable y jurisdicción competente
      </div>
      <p className="text-muted">
        La normativa vigente determinará las leyes que deben regir y la
        jurisdicción que debe conocer de las relaciones entre la EMPRESA y los
        Usuarios del Sitio Web. Esto no obstante, siempre que dicha normativa
        prevea la posibilidad para las partes de someterse a un fuero
        determinado, para cualquier litigio derivado o relacionado con este
        Sitio Web, serán de aplicación la legislación española vigente en el
        momento del litigio. Asimismo, la EMPRESA y los Usuarios, con renuncia
        expresa a cualquier otro fuero que pudiera corresponderles, se someten a
        los Juzgados y Tribunales del domicilio del Usuario para cualquier
        controversia que pudiera surgir. En el caso de que el Usuario tenga su
        domicilio fuera de España, la EMPRESA y el Usuario se someterán a los
        Juzgados y Tribunales de la ciudad de Las Palmas de Gran Canaria.
      </p>
    </div>
  )
}
