import {
  DividendReceived,
  Exchanges,
  MySecurities,
  SecuritieInfoModel,
  SecuritieInfoOperation,
  SecuritiesHistoricPrices,
} from '../../models/securities.models'
import { coins, getNumericValue } from '../../utils/coins'
import { getExchange } from '../../views/PrivateZone/Securities/MySecurities/MySecurities.utils'
import axiosApi, { dataToURLSearchParams } from '../AxiosConfig'
import {
  DividendReceivedDomain,
  SecuritieInfoDomain,
  SecuritieInfoOperationDomain,
  SecuritiesHistoricPricesDomain,
} from '../domainModels/securities.models'
import { camelCaseToSnakeCaseObject } from '../mappers/general.mappers'
import {
  dividendReceivedDomainToModel,
  securitieDomainToModel,
  securitieHistoricPrice,
  securitieOperationDomainToModel,
} from '../mappers/securities.mappers'

export const getSecuritiesGeneralData = async (): Promise<
  SecuritieInfoModel[]
> => {
  const response: { securitiesGeneralData: SecuritieInfoDomain[] } =
    await axiosApi.get('securities/getSecuritiesGeneralData')

  return response.securitiesGeneralData.map(securitieDomainToModel)
}

export const getSecuritieValue = async (
  ticker: string,
): Promise<{ value: number }> => {
  let response: { value: string }

  response = await axiosApi.get(
    'securities/getSecuritieValues',
    dataToURLSearchParams({
      ticker,
    }),
  )

  const responseNumber = getNumericValue(response.value)
  return { value: responseNumber }
}

export const getMySecuritiesOperations = async (
  idUser: string,
): Promise<SecuritieInfoOperation[]> => {
  const historic: { mySecurities: SecuritieInfoOperationDomain[] } =
    await axiosApi.get(
      'securities/getMySecurities',
      dataToURLSearchParams({ idUser }),
    )

  return historic.mySecurities.map((securitie) =>
    securitieOperationDomainToModel(securitie),
  )
}

const getExchangeCurrencies = async (): Promise<Exchanges[]> => {
  const parseCurrencies = (parsedData) => {
    const securitiesToSet: Exchanges[] = parsedData.table.rows
      .filter((row) => row.c[0] !== null)
      .map((row) => ({
        price: row.c[0].v,
        currency: row.c[1].v,
        covertTo: row.c[2].v,
        valueConverted: row.c[3].v,
      }))

    return securitiesToSet
  }
  const bolsaURL =
    'https://docs.google.com/spreadsheets/d/11Ih0AcdwpY9S6aaB0-lHixOFAOztnva8DwIcTOUqxSI/gviz/tq?tqx=out:json&gid=0'

  return fetch(bolsaURL)
    .then((response) => {
      return response.text()
    })
    .then((data) => {
      // Procesa los datos y actualiza tu página web
      const startIndex = data.indexOf('{')
      const endIndex = data.lastIndexOf(')')
      const jsonResponse = data.substring(startIndex, endIndex)

      // Parsear el JSON
      const parsedData = JSON.parse(jsonResponse)
      const currenciesToSet = parseCurrencies(parsedData)

      return currenciesToSet
    })
    .catch((error) => {
      console.error('Error al obtener datos:', error)
      return [] as Exchanges[]
    })
}

export const getMySecurities = async (
  idUser: string,
  currency: string,
): Promise<MySecurities> => {
  const securitiesOperations = await getMySecuritiesOperations(idUser)
  const securitiesGeneralData = await getSecuritiesGeneralData()
  const exchanges = await getExchangeCurrencies()

  const mySecurities = await securitiesOperations.map(async (securitie) => {
    const { id, ...generalSecuritieInfo } = securitiesGeneralData.find(
      (securitieInfo) => securitieInfo.id === securitie.securitieInternalId,
    )
    const currentValue = await getSecuritieValue(
      generalSecuritieInfo.googleTicker,
    )

    let currentValueInUserCurrency: number
    let buyPriceInEUR: number
    let sellPriceInEUR: number = null
    if (generalSecuritieInfo.currency !== coins[currency]) {
      currentValueInUserCurrency = getExchange(
        currentValue.value,
        generalSecuritieInfo.currency,
        currency,
        exchanges,
      )
      buyPriceInEUR = getExchange(
        securitie.buyPrice,
        generalSecuritieInfo.currency,
        currency,
        exchanges,
      )
      if (!!securitie.sellPrice) {
        buyPriceInEUR = getExchange(
          securitie.buyPrice,
          generalSecuritieInfo.currency,
          currency,
          exchanges,
        )
      }
    }

    const amounts = {
      currentValueInOriginCurrency: currentValue.value,
      currentValueInUserCurrency,
      buyPriceInEUR: buyPriceInEUR,
      sellPriceInEUR: sellPriceInEUR,
    }
    return {
      ...securitie,
      ...generalSecuritieInfo,
      ...amounts,
    }
  })
  const mySecuritiesResolved = await Promise.all(mySecurities)

  return mySecuritiesResolved as unknown as Promise<MySecurities>
}

export const notifySellSecuritie = async (
  securitieOperation: SecuritieInfoOperation,
  sellShares: number,
  sellDate: Date,
  sellPrice: number,
) => {
  let response: { ok: string }

  response = await axiosApi.post(
    'securities/notifySellSecuritie',
    dataToURLSearchParams({
      ...camelCaseToSnakeCaseObject(securitieOperation),
      sellDate,
      sellPrice,
      sellShares,
    }),
  )

  return response
}

export const getMyDividends = async (
  idUser: string,
): Promise<DividendReceived[]> => {
  const dividends: DividendReceivedDomain[] = await axiosApi.get(
    'securities/getMyDividends',
    dataToURLSearchParams({ idUser }),
  )
  return dividends.map((dividend) => dividendReceivedDomainToModel(dividend))
}

export const notifyPurchaseSecuritie = async (
  securitieOperation: SecuritieInfoOperation,
) => {
  const response: { ok: string } = await axiosApi.post(
    'securities/notifyPurchaseSecuritie',
    dataToURLSearchParams(camelCaseToSnakeCaseObject(securitieOperation)),
  )
  return response
}

export const getHistoricPrices = async (
  id: string,
): Promise<SecuritiesHistoricPrices> => {
  const historic: SecuritiesHistoricPricesDomain = await axiosApi.get(
    'securities/getSecuritieHistoricPrice',
    dataToURLSearchParams({ id }),
  )

  return securitieHistoricPrice(historic)
}
