import React, { useState, useEffect } from 'react'
/* import { loadStripe } from '@stripe/stripe-js'
 */ import { Elements } from '@stripe/react-stripe-js'

import CheckoutForm from './CheckoutForm'
// import './App.css'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
/* const stripePromise = loadStripe(
  'pk_test_51OYT1KFykmcjIhnLUXiAVejJxmEcQ1tsoqvcDOIpEAvhpxWviVGWLxTEEJ0vfBmXFeoFLOJkeVq7JBKZcrl5EMhw002ks2C9cn',
) */

export default function Pago() {
  const [clientSecret, _setClientSecret] = useState('jk')

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    /*     fetch('/create.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret)) */
  }, [])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <div className="App">
      {clientSecret && (
        /* @ts-ignore */
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}
