import * as crypto from 'crypto-js'

export const generateRandomString = (complexy?: number) => {
  // TODO;: hacer regez para que solo devuelva numeros y letras
  var wordArray = crypto.lib.WordArray.random(complexy ?? 100)
  var randomString = crypto.enc.Base64.stringify(wordArray).replace(
    /[^0-9a-zA-Z]/g,
    '',
  )
  return randomString
}

export function firstLetterCap(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
