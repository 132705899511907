import { Products, TypeOfContractService } from './../../models/users.models'
import { User } from '../../models/users.models'
import { UserDomain } from '../domainModels/users.models'
import { SignUpType } from '../../views/Login/SignUpProccess/context/SignUp.context'
import { generateRandomString } from '../../utils/general'

export const userDomainToUserView = (userDomain: UserDomain): User => {
  const { currency, ...rest } = userDomain

  return {
    ...rest,
    identificationType: rest.identification_type,
    paymentMethod: rest.payment_method,
    contractedService:
      userDomain.contracted_service as unknown as TypeOfContractService,
    grossSalary: { value: parseFloat(userDomain.gross_salary), currency },
    birthdate: new Date(rest.birthdate),
    products: rest.products ? (rest.products.split(';') as Products[]) : [],
    currency: currency,
  }
}

export const signUpToSignUpApi = (signUp: SignUpType) => ({
  id: signUp.firstInfo.id,
  email: signUp.firstInfo.email,
  name: signUp.personalData.name,
  surname: signUp.personalData.surname,
  pass: signUp.firstInfo.password,
  grossSalary: signUp.economicData.grossSalary.value,
  contractedService: signUp.services.contractedService,
  identification: signUp.personalData.identification,
  identificationType: signUp.personalData.identificationType,
  birthdate: signUp.personalData.birthdate,
  status: signUp.firstInfo.status,
  currency: signUp.economicData.grossSalary.currency,
})

export const signUpToSavingsApi = (signUp: SignUpType) => ({
  estimatedSavingMonth: signUp.economicData.estimatedSavingMonth.value,
  idSaving: generateRandomString(30),
  savings: signUp.economicData.savings.value,
  idUser: signUp.firstInfo.id,
})
