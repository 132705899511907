import * as React from 'react'
import { Helmet } from 'react-helmet'

export const useHelmet = ({
  title,
  description,
  keywords,
  isNotIndexed,
}: {
  title: string
  description: string
  keywords: string
  isNotIndexed?: boolean
}) => {
  return (
    <Helmet>
      <title>{title}</title>

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Natalia Bravo" />

      {isNotIndexed && <meta name="robots" content={'Noindex'} />}
    </Helmet>
  )
}
