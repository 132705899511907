import * as React from 'react'
import { useHelmet } from '../../../hooks/useHelmet'

export const TreasureLetters = () => {
  return (
    <>
      {useHelmet({
        title: 'Invertir en letras del tesoro',
        description:
          '🎉Invierte en seguridad y liquidez con letras del tesoro, instrumentos financieros respaldados por el gobierno, ideales para preservar capital.🎉',
        keywords:
          'Letras del tesoro, ¿Qué son las letras del tesoro?, Invertir en letras del tesoro',
      })}
      <div className="container">
        <h1 className="mt-5 mb-3">¿Qué son las letras del Tesoro?</h1>
        <p>
          Las letras del Tesoro son instrumentos de deuda pública a corto plazo
          emitidos por el Tesoro Público para cubrir las necesidades financieras
          del Estado. Si lo que buscas es conocer la rentabilidad de esta
          inversión, te recomendamos echarle un vistazo al artículo{' '}
          <a href="/herramientas/calculadora-rentabilidad/letras-del-tesoro">
            "Calculadora de inversión en letras del tesoro"
          </a>
        </p>

        <h2 className="mt-5 mb-3">Ventajas de las letras del Tesoro</h2>
        <ul>
          <li>
            <strong>FIABILIDAD:</strong> Respaldadas por el Tesoro Público.
          </li>
          <li>
            <strong>LIQUIDEZ:</strong> Pueden ser comercializadas sin necesidad
            de esperar al vencimiento.
          </li>
          <li>
            <strong>TRANQUILIDAD:</strong> Al conocer de antemano la
            rentabilidad esperada.
          </li>
          <li>
            <strong>RENDIMIENTO:</strong> Atractivo, considerando el riesgo
            asumido.
          </li>
        </ul>

        <h2 className="mt-5 mb-3">¿Cómo operan las letras del Tesoro?</h2>
        <p>
          Las letras del Tesoro funcionan como activos de renta fija a corto
          plazo, siendo emitidas con plazos de vencimiento de 3, 6, 9 y 12
          meses.
        </p>
        <p>
          De forma mensual, se realiza una subasta para la emisión de letras del
          Tesoro, en la cual se publica su tasa de rendimiento. Cualquier
          persona puede participar en la subasta y adquirir letras del Tesoro
          con una inversión mínima de 1.000€ por solicitud. El calendario de
          subastas está disponible en el sitio web del Tesoro Público.
        </p>
        <p>
          Recuerda que puedes adquirir letras del Tesoro en las sucursales del
          Banco de España, en el portal web del Tesoro Público o a través de
          instituciones financieras.
        </p>
      </div>
    </>
  )
}
