import * as React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Field } from '../../views/components/Field'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useService } from '../../hooks/useService'
import { dislockBeta } from '../../domain/services/users.service'
import { MainButton } from '../../views/components/Buttons'
import { userTypeToIntegration } from '../../utils/storage'

export const BetaModal = ({
  isOpen,
  onClose,
}: /*   forceSubscription, */
{
  isOpen?: boolean
  onClose?: () => void
  forceSubscription?: boolean
}) => {
  const { onExecute, state } = useService({
    callBack: dislockBeta,
    executeOnStart: false,
  })

  const handleClose = () => {
    if (!!onClose) {
      onClose()
    }
  }

  const {
    register,
    trigger,
    control,
    formState: { errors },
    getValues,
  } = useForm<{ pass: string }>({
    mode: 'all',
  })

  React.useEffect(() => {
    trigger()
  }, [])

  React.useEffect(() => {
    if (state === 'success') {
      localStorage.setItem('userType', userTypeToIntegration)
      handleClose()
    }
  }, [state])

  const onSave = (pass: string) => {
    onExecute({ pass })
  }

  if (!isOpen) {
    return null
  }

  return (
    <>
      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton={isOpen}>
          <Modal.Title>Introduce la clave</Modal.Title>
        </Modal.Header>
        <form onSubmit={null} className="mb-3">
          <Modal.Body>
            <Field control={control} label="Clave" name="pass">
              <Form.Control
                placeholder="XXXX"
                {...register('pass', { required: true })}
              />
            </Field>
          </Modal.Body>
          <Modal.Footer>
            <MainButton
              disabled={!!errors.pass}
              onClick={() => onSave(getValues().pass)}
            >
              Continuar
            </MainButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
