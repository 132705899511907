import {
  Bill,
  BuyAndSellInfo,
  HouseInfo,
  Income,
} from '../../models/house.models'
import { englishFormatToDate } from '../../utils/date'
import {
  BillDomain,
  BuyAndSellInfoDomain,
  HouseInfoDomain,
  IncomeDomain,
} from '../domainModels/houses.models'
import { snakeCaseToCamelCase } from './general.mappers'

export const houseModelToHouseModelView = (
  houseInfo: HouseInfoDomain,
): HouseInfo => {
  const { currency, ...rest } = houseInfo

  return {
    ...(snakeCaseToCamelCase(rest) as Omit<HouseInfo, 'currency'>),
    ownCapitalSpentOnPurchase: {
      value: parseFloat(rest.own_capital_spent_on_purchase),
      currency,
    },
    sellPrice: !!rest.sell_price
      ? {
          value: parseFloat(rest.sell_price),
          currency,
        }
      : null,
    buyPrice: {
      value: parseFloat(rest.buy_price),
      currency,
    },
    realInvestmentBeforeSell: {
      value: parseFloat(rest.real_investment_before_sell),
      currency,
    },
    totalPurchaseCosts: {
      value: parseFloat(rest.total_purchase_costs),
      currency,
    },
    currentPrice: {
      value: parseFloat(rest.current_price),
      currency,
    },
    idealSellPrice: {
      value: parseFloat(rest.ideal_sell_price),
      currency,
    },
    annualGrossProfit: {
      value: parseFloat(rest.annual_gross_profit),
      currency,
    },
    hasElevator:
      rest.has_elevator === 'NA'
        ? rest.has_elevator
        : rest.has_elevator === 'true',
  }
}

export const BillModelToBillModelView = (bill: BillDomain): Bill => {
  const fechaformateada = englishFormatToDate(bill.start_date)
  const prueba = {
    amount: { value: parseFloat(bill.amount), currency: bill.currency },
    date: englishFormatToDate(bill.date),
    startDate: fechaformateada,
    finishDate: englishFormatToDate(bill.finish_date),
    name: bill.name,
    category: bill.category,
    description: bill.description,
    repetition: bill.repetition,
    houseId: bill.house_id,
  }

  return prueba
}

export const IncomeModelToIncomeModelView = (income: IncomeDomain): Income => {
  const { currency, ...rest } = income

  return {
    ...(snakeCaseToCamelCase(rest) as Omit<Income, 'currency'>),
    amount: { value: parseFloat(rest.amount), currency },
    date: englishFormatToDate(rest.date),
    startDate: englishFormatToDate(rest.start_date),
    finishDate: englishFormatToDate(rest.finish_date),
  }
}

export const buyAndSellModelToBuyAndSellModelView = (
  info: BuyAndSellInfoDomain,
): BuyAndSellInfo => {
  const { currency, ...rest } = info

  return {
    ...(snakeCaseToCamelCase(rest) as Omit<BuyAndSellInfo, 'currency'>),
    buyPrice: { value: parseFloat(rest.buy_price), currency },
    renovationPrice: { value: parseFloat(rest.renovation_price), currency },
    notaryAmount: { value: parseFloat(rest.notary_amount), currency },
    registrationAmount: {
      value: parseFloat(rest.registration_amount),
      currency,
    },
    agencyCommissionAmount: {
      value: parseFloat(rest.agency_commission_amount),
      currency,
    },
    managementAmount: { value: parseFloat(rest.management_amount), currency },
    appraisal: { value: parseFloat(rest.appraisal), currency },
    withMortgage: rest.with_mortgage === 'true',
  }
}
