import * as React from 'react'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import Overlay from 'react-bootstrap/Overlay'
import TooltipBootstrap from 'react-bootstrap/Tooltip'

export const Tooltip = ({ tooltip, tooltipText }) => {
  const [showA, setShowA] = React.useState(true)

  const toggleShowA = () => setShowA(!showA)

  return (
    <>
      <Button onClick={toggleShowA} className="mb-2">
        {tooltip}
      </Button>
      <Toast show={showA} onClose={toggleShowA}>
        {/*         <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header> */}
        <Toast.Body>{tooltipText}</Toast.Body>
      </Toast>
    </>
  )
}

export const TooltipHover = ({
  info,
  title,
  withInfoIcon,
}: {
  info: string
  title: any
  withInfoIcon?: boolean
}) => {
  const [show, setShow] = React.useState(false)
  const target = React.useRef(null)

  React.useEffect(() => {
    if (show) {
      const handleOutsideClick = (event) => {
        if (target.current && !target.current.contains(event.target)) {
          setShow(false)
        }
      }
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', null)
    }
  }, [show])

  return (
    <>
      <div
        ref={target}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setShow(!show)
        }}
      >
        {title} {withInfoIcon && <i className="bi bi-info-circle"></i>}
      </div>
      <Overlay target={target.current} show={show} placement="right">
        {(props) => (
          <TooltipBootstrap /* id="overlay-example" */ {...props}>
            {info}
          </TooltipBootstrap>
        )}
      </Overlay>
    </>
  )
}
