import { Exchanges } from '../../../../models/securities.models'
import { SecuritieInfo } from '../Context/Securities.context'

export const getSecuritiesInfo = (parsedData) => {
  const securitiesToSet: SecuritieInfo[] = parsedData.table.rows
    .filter((row) => row.c[0] !== null)
    .map((row) => ({
      name: row.c[0].v,
      id: row.c[1].v,
      currentValueInOriginCurrency: row.c[2].v,
      currencie: row.c[3].v,
      currentValueInEUR: row.c[3].v === 'eur' ? row.c[2].v : row.c[5].v,
    }))

  return securitiesToSet
}

export const getExchangeCurrencies = (parsedData) => {
  const securitiesToSet: Exchanges[] = parsedData.table.rows
    .filter((row) => row.c[0] !== null)
    .map((row) => ({
      price: row.c[0].v,
      currency: row.c[1].v,
      covertTo: row.c[2].v,
      valueConverted: row.c[3].v,
    }))

  return securitiesToSet
}

export const getExchange = (
  value: number,
  originCurrency: string,
  currency: string,
  currencies: Exchanges[],
) => {
  let result = 0
  if (currency.toLowerCase() === originCurrency.toLowerCase()) {
    result = parseFloat(value.toString())
  } else {
    const info = currencies.find(
      (id) =>
        id.currency.toLocaleLowerCase() ===
          originCurrency.toLocaleLowerCase() &&
        id.covertTo.toLocaleLowerCase() === currency.toLocaleLowerCase(),
    )

    result = parseFloat(value.toString()) * parseFloat(info.valueConverted)
  }

  return result
}
