import { monthSpanish } from '../models/general.models'
import dayjs from 'dayjs'

export const englishFormatToDate = (date: string) => {
  if (!!date) {
    return dayjs(date).toDate()
  }
  return null
}

export const englishFormatDate = (date: string | Date) => {
  let objectDate: Date = date as any
  if (typeof date === 'string') {
    objectDate = new Date(date)
  }

  let day = objectDate.getDate()

  let month = objectDate.getMonth()

  let year = objectDate.getFullYear()

  return `${year}-${(month + 1).toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
}

export const stringDateToDate = (
  date: string,
  formatType?: 'date' | 'english' | 'spanishText',
) => {
  let dateInfo = date.split('-')
  let day = dateInfo[2]
  let month = dateInfo[1]
  let year = dateInfo[0]

  if (formatType === 'spanishText') {
    return `${day} ${monthSpanish[month]} ${year}`
  }
  if (formatType === 'english') {
    return `${year}-${month}-${day}`
  }

  return `${month}/${day}/${year}`
}

export const formatDate = (date: string | Date) => {
  let objectDate: Date = date as any
  if (typeof date === 'string') {
    objectDate = new Date(date)
  }

  let day = objectDate.getDate()

  let month = objectDate.getMonth()

  let year = objectDate.getFullYear()

  return `${day} ${monthSpanish[month]} ${year}`
}

export const getDateSlit = (
  date: string | Date,
): { day: number; month: number; year: number } => {
  let objectDate: Date = date as any
  if (typeof date === 'string') {
    objectDate = new Date(date)
  }

  let day = objectDate.getDate()

  let month = objectDate.getMonth()

  let year = objectDate.getFullYear()

  return { day, month, year }
}

export const today = new Date()

export const isSameYear = (date: Date | string, yearToCompare: number) => {
  let objectDate: Date = date as any
  if (typeof date === 'string') {
    objectDate = new Date(date)
  }

  let year = objectDate.getFullYear()

  return year === yearToCompare
}

export const isCurrentYear = (date: Date | string) => {
  let objectDate: Date = date as any
  if (typeof date === 'string') {
    objectDate = new Date(date)
  }

  return isSameYear(objectDate, today.getFullYear())
}
