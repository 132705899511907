import { User } from '../../models/users.models'
import { SignUpType } from '../../views/Login/SignUpProccess/context/SignUp.context'
import axiosApi, { dataToURLSearchParams } from '../AxiosConfig'
import { UserDomain } from '../domainModels/users.models'
import {
  signUpToSavingsApi,
  signUpToSignUpApi,
  userDomainToUserView,
} from '../mappers/users.mappers'

export const getuserInfo = async (identification: string): Promise<User> => {
  const userInfo: UserDomain = await axiosApi.post(
    'users/getUserInfo',
    dataToURLSearchParams({ identification }),
  )
  console.log('userInfo', userInfo)
  return userDomainToUserView(userInfo)
}

export const getUserEconomicResume = async (idUser: string): Promise<User> => {
  const userInfo: UserDomain = await axiosApi.post(
    'users/getUserInfo',
    dataToURLSearchParams({ idUser }),
  )
  console.log('userInfo', userInfo)
  return userDomainToUserView(userInfo)
}

export const onChangeUserInfo = async ({
  oldPassword,
  newPassword,
}): Promise<{ ok: 'ok' }> => {
  const response: { ok: 'ok' } = await axiosApi.post(
    'users/updateUserInfo',
    dataToURLSearchParams({ oldPassword, newPassword }),
  )
  return response
}

// proceso login

export const singIn = async ({ identification, pass }): Promise<User> => {
  const userInfo: UserDomain = await axiosApi.post(
    'users/signIn',
    dataToURLSearchParams({ identification, pass }),
  )
  return userDomainToUserView(userInfo)
}

export const signUp = async (info: SignUpType): Promise<{ ok: 'ok' }> => {
  /*   const responseSignUp: { ok: 'ok' } =  */ await axiosApi.post(
    'users/signUp',
    dataToURLSearchParams(signUpToSignUpApi(info)),
  )
  /*  const responseSavings: { ok: 'ok' } = */ await axiosApi.post(
    'users/setSavings',
    dataToURLSearchParams(signUpToSavingsApi(info)),
  )
  return { ok: 'ok' }
}

export const onPayService = async ({ userId }): Promise<{ ok: 'ok' }> => {
  /*  const responseOnPay: { ok: 'ok' } = */ await axiosApi.get(
    'users/onPayService',
    dataToURLSearchParams({ lookup_key: 'gt-b--anual' }),
  )
  /* const responseChangeStatus: { ok: 'ok' } =  */ /* await axiosApi.post(
    'users/changeStatus',
    dataToURLSearchParams({ id: userId, status: 'active' }),
  ) */

  return { ok: 'ok' }
}

export const onChangePassword = async ({
  oldPassword,
  newPassword,
}): Promise<{ ok: 'ok' }> => {
  const response: { ok: 'ok' } = await axiosApi.post(
    'users/changePassword',
    dataToURLSearchParams({ oldPassword, newPassword }),
  )
  return response
}

export const toSubscribeFree = async ({ email, id }): Promise<string> => {
  const response: string = await axiosApi.post(
    'users/toSubscribeFree',
    dataToURLSearchParams({ email, id }),
  )
  /*   if (response === 'ok') {
    console.log('todo bien')
    const responsePago: string = await axiosApi.get(
      'users/onPayService',
      dataToURLSearchParams({ lookup_key: 'gt-b--anual' }),
    )
    console.log('responsePago', responsePago)
  } */

  return response
}

export const dislockBeta = async ({ pass }): Promise<string> => {
  const response: string = await axiosApi.post(
    'users/secretKey',
    dataToURLSearchParams({ pass }),
  )

  return response
}

export const apiPruebas = async ({ identification, id }): Promise<string> => {
  const response: string = await axiosApi.post(
    'users/apiPruebas',
    dataToURLSearchParams({ identification, id }),
  )
  /*   if (response === 'ok') {
    console.log('todo bien')
    const responsePago: string = await axiosApi.get(
      'users/onPayService',
      dataToURLSearchParams({ lookup_key: 'gt-b--anual' }),
    )
    console.log('responsePago', responsePago)
  } */

  return response
}
